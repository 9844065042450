<template>
  <div class="cwp-map">
    <cwp-loader :loading="loading" class="cwp-loader--absolute" />
    <div class="cwp-map__frame" height="300" width="100%"></div>
  </div>
</template>

<script>
import CwpLoader from '@/components/CwpLoader/CwpLoader.vue'
import { mapState } from 'vuex'
export default {
  name: 'CwpMap',
  components: {
    CwpLoader,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState({
      page: state => state.site.page,
      pageSettings: state => state.site.pageSettings,
    }),
  },
  mounted() {
    // check google maps has been loaded
    var googleAvailable = setInterval(() => {
      if (window.google) {
        clearInterval(googleAvailable)

        let color = this.page?.customData?.styles?.primaryColor ?? this.pageSettings?.styles?.primaryColor
        const marker = `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fill-rule="evenodd" transform="translate(-389 -838)">
                <g transform="translate(389 838)">
                    <path fill="${
                      color || '#444'
                    }" d="M11.679.75c4.142 0 7.5 3.358 7.5 7.5 0 4.221-2.933 6.649-5.131 9-1.316 1.408-2.37 6-2.37 6l-.01-.044c-.11-.47-1.114-4.625-2.352-5.949-2.2-2.35-5.137-4.782-5.137-9.007 0-4.142 3.357-7.5 7.5-7.5zm0 4.5c-1.657 0-3 1.343-3 3s1.343 3 3 3c1.656 0 3-1.343 3-3s-1.344-3-3-3z"/>
                </g>
            </g>
        </svg>`
        const options = Object.assign(
          {
            center: new google.maps.LatLng(this.latitude, this.longitude),
            zoom: 12,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
          },
          this.options
        )

        const map = new google.maps.Map(this.$el.querySelector('.cwp-map__frame'), options)

        new google.maps.Marker({
          position: { lat: this.latitude, lng: this.longitude },
          icon: {
            url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(marker),
            size: new google.maps.Size(64, 64),
            scaledSize: new google.maps.Size(64, 64),
          },
          map,
        })

        google.maps.event.addListenerOnce(map, 'idle', () => {
          this.loading = false
        })
      }
    }, 1000)
  },
}
</script>

<style lang="scss">
.cwp-map {
  height: 300px;
  position: relative;

  @include media('>=medium') {
    height: 250px;
  }

  &__frame {
    height: 100%;
  }
}
</style>
