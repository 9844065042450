<template>
  <section class="grid-container">
    <rv-feature
      v-for="(feature, i) in container.features"
      :key="i"
      :picture="feature.picture"
      :picture-position="i % 2 ? 'right' : 'left'"
      :image-options="{ width: 585 }"
    >
      <!-- eslint-disable-next-line -->
      <div v-html="feature.htmlContent" />
    </rv-feature>
  </section>
</template>

<script>
export default {
  name: 'CwpFeatureContainer',
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
}
</script>
