<template>
  <div class="cwp-view">
    <template v-for="(container, index) in components">
      <cwp-component-margin
        v-if="availableComponents.includes(containerClass(container.className))"
        :key="index"
        :padding-bottom="container.hasBottomPadding"
      >
        <component
          :is="containerClass(container.className)"
          :id="container.id"
          :container="container"
          :class="[utilityClassGenerator(containerClass(container.className), container), `guid-${container.id}`]"
        />
      </cwp-component-margin>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { utilityClassGenerator } from '@/functions/utils.js'
import CwpComponentMargin from '@/components/CwpComponentMargin/CwpComponentMargin'
import components from '@/components/ComponentLoop'

export default {
  name: 'Default',
  components: {
    ...components,
    CwpComponentMargin,
  },
  data() {
    return {
      availableComponents: Object.keys(components),
    }
  },
  computed: {
    ...mapState({
      page: state => state.site.page,
      components: state => state.site.components,
    }),
  },
  mounted() {
    this.$nextTick(function () {
      if (this.$route.hash !== '') {
        const elementId = this.$route.hash.replace('#', '')
        const element = document.getElementById(elementId)
        if (elementId.match(/^\d+$/) && element != null) {
          setTimeout(() => {
            window.scrollTo({
              left: 0,
              top: element.offsetTop,
              behavior: 'smooth',
            })
          }, 1000)
        }
      }
    })
  },
  methods: {
    utilityClassGenerator,
    containerClass(className) {
      return className.replace('Revium_', 'Cwp')
    },
  },
}
</script>

<style lang="scss">
.cwp-view {
  word-break: break-word;
}
</style>
