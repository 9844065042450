<template>
  <span class="cell">
    <rv-card :card="card">
      <template #content>
        <div v-if="card.title" class="rv-card__title cwp-news-card__title">
          {{ card.title }}
        </div>
        <div v-if="card.publicationDate" class="cwp-news-card__publication-date">
          {{ formatDate(card.publicationDate) }}
        </div>
        <div v-if="card.summaryText" class="rv-card__content" v-html="card.summaryText" />
        <div class="rv-card__cta">
          <cwp-link :to="card.url" button class="rv-card__button">Read more</cwp-link>
        </div>
      </template>
    </rv-card>
  </span>
</template>

<script>
import { RvCard } from '@revium/components'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import { formatDate } from '@/functions/utils'
export default {
  name: 'CwpNewsCard',
  components: {
    CwpLink,
    RvCard,
  },
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate,
  },
}
</script>

<style lang="scss">
.cwp-news-card {
  &__title {
    color: var(--heading-font-color);
  }

  &__publication-date {
    margin-bottom: 8px;
  }
}
</style>
