var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"cwp-project-finder"},[(!_vm.container.hideMap)?_c('cwp-project-map',{attrs:{"locations":_vm.filteredProjects,"state":_vm.container.state,"select-project":_vm.selectProject}}):_vm._e(),_c('div',{staticClass:"grid-container"},[(!_vm.container.hideMap)?_c('div',{staticClass:"cwp-project-finder__status-marker-container",class:_vm.container.headerText ? '' : 'cwp-project-finder__status-marker-container--add-margin'},[_c('CwpStatusMarker',{attrs:{"status":"complete"}}),_c('CwpStatusMarker',{attrs:{"status":"in progress"}}),_c('CwpStatusMarker',{attrs:{"status":"coming soon"}})],1):_vm._e(),(_vm.container.headerText)?_c('h1',[_vm._v(_vm._s(_vm.container.headerText))]):_vm._e(),(_vm.showFilters)?_c('div',{staticClass:"cwp-project-finder__filters-container"},[_vm._l((_vm.filterSelects),function(filter,filterId){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(filter.show),expression:"filter.show"}],key:filterId,staticClass:"cwp-project-finder__filter-input-container"},[_c('label',{attrs:{"for":filterId}},[_c('cwp-icon',{staticClass:"filter-icon",attrs:{"name":`${filter.icon}.svg`,"alt":"Location marker"}}),_vm._v(" "+_vm._s(filter.label)+" ")],1),_c('rv-select',{ref:filterId,refInFor:true,staticClass:"filter-input",attrs:{"field":{
            id: filterId,
            name: filterId,
            value: _vm.filters[filterId],
            options: filter.options,
          }},on:{"input":(formName, fieldName, value) => {
              _vm.filters[filterId] = value
            }}})],1)}),_c('div',{staticClass:"cwp-project-finder__filter-input-container"},[_c('label',{attrs:{"for":"Search"}},[_c('cwp-icon',{staticClass:"filter-icon",attrs:{"name":"search.svg","alt":"Location marker"}}),_vm._v(" Search ")],1),_c('rv-input',{ref:"searchTerm",staticClass:"filter-input",attrs:{"field":{
            id: 'searchTerm',
            name: 'searchTerm',
            placeholder: '',
            type: 'text',
            value: _vm.filters.searchTerm,
          }},on:{"input":(formName, fieldName, value) => {
              _vm.filters.searchTerm = value
            }}})],1)],2):_vm._e(),(_vm.showFilters)?_c('cwp-link',{staticClass:"button secondary clear-filters",attrs:{"button":"","tag":"button"},on:{"click":_vm.clearFilters}},[_vm._v(" Clear Filters ")]):_vm._e(),(_vm.filteredProjects.length)?_c('div',{staticClass:"cwp-project-finder__projects-container"},_vm._l((_vm.filteredProjects),function(project){return _c('CwpProjectCard',{key:project.name,attrs:{"project-data":project,"select-project":_vm.selectProject,"base-url":_vm.container.baseUrl}})}),1):_c('div',{staticClass:"cwp-project-finder__no-projects"},[_vm._m(0)])],1),_c('cwp-modal',{attrs:{"value":!!_vm.selectedProject,"prevent-scroll-top":"","size":"large","remove-padding":""},on:{"input":() => _vm.selectProject(null)}},[(!!_vm.selectedProject)?_c('cwp-project-detail',{attrs:{"project-data":_vm.selectedProject,"base-url":_vm.container.baseUrl}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("No projects found")])])
}]

export { render, staticRenderFns }