<template>
  <section :class="isFloating ? '' : 'grid-container padding-y'">
    <component
      :is="isFloating ? 'cwp-modal' : 'div'"
      v-model="modal"
      :aria-labelledby="`form-label-${container.id}`"
      :aria-describedby="`form-description-${container.id}`"
    >
      <div class="cwp-generic-form">
        <div
          v-if="(container.heading || container.description) && (!showThankYouSingle || persistHeading)"
          class="cwp-generic-form__header"
        >
          <h3 v-if="container.heading" :id="`form-label-${container.id}`" class="cwp-generic-form__heading">
            {{ container.heading }}
          </h3>
          <p
            v-if="container.description"
            :id="`form-description-${container.id}`"
            class="cwp-generic-form__description"
            v-html="container.description"
          />
        </div>
        <div class="cwp-generic-form__wrapper">
          <rv-form
            v-if="!showThankYouSingle && !showError"
            :name="container.formName"
            :form-id="container.id"
            :fields="fields"
            :api="api"
            :csrf="csrf"
            scroll-to-invalid
            :scroll-to-invalid-offset="breakpoints.large ? -160 : -100"
            :use-recaptcha="integrations.googleRecaptchaEnabled"
            @success="formSuccess"
            @error="
              showError = true
              loading = false
            "
            @valid="loading = true"
          >
            <template #date="{ updateFormValue, fieldName, formName, field }">
              <cwp-date-picker
                :field-name="fieldName"
                :form-name="formName"
                :field="field"
                placeholder="DD/MM/YYYY"
                @input="updateFormValue"
              >
                <template #messages="{ errors }">
                  <cwp-messages :field="field" :errors="errors" />
                </template>
              </cwp-date-picker>
            </template>
            <template #text="{ updateFormValue, fieldName, formName, field }">
              <rv-input
                :ref="`${formName}-${fieldName}`"
                :field-name="fieldName"
                :form-name="formName"
                :field="field"
                @input="updateFormValue"
              >
                <template #messages="{ errors }">
                  <cwp-messages :field="field" :errors="errors" />
                </template>
              </rv-input>
            </template>
            <template #addressLookup="{ updateFormValue, fieldName, formName, field }">
              <cwp-address-lookup
                :field-name="fieldName"
                :form-name="formName"
                :field="field"
                @input="updateFormValue"
                @postcode="updatePostcode"
              >
                <template #messages="{ errors }">
                  <cwp-messages :field="field" :errors="errors" />
                </template>
              </cwp-address-lookup>
            </template>
            <template #submit="{ submitForm }">
              <cwp-link class="button primary" tag="button" :loading="loading" @click="submitForm()">{{
                submitText
              }}</cwp-link>
            </template>
          </rv-form>
          <template v-if="showThankYouSingle">
            <div class="rv-form--thanks">
              <div class="rv-form__icon" />
              <div v-html="container.thankYouContent" />
            </div>
          </template>
          <template v-if="showError">
            <div class="rv-form--error">
              <div class="rv-form__icon" />
              Whoops! Looks like something went wrong.
            </div>
          </template>
        </div>
      </div>
    </component>
    <div v-if="isFloating" class="cwp-generic-form__floating-button">
      <cwp-icon name="callback.svg" interactive tabindex="0" alt="Open dialog" @click="modal = true" />
    </div>
  </section>
</template>

<script>
import { RvForm, RvInput, RvSelect } from '@revium/components'
import { mapState } from 'vuex'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpDatePicker from '@/components/CwpDatePicker/CwpDatePicker.vue'
import CwpAddressLookup from '@/components/CwpAddressLookup/CwpAddressLookup'
import CwpMessages from '@/components/CwpMessages/CwpMessages.vue'
import CwpModal from '@/components/CwpModal/CwpModal.vue'
import { buildRules } from '@/plugins/veeValidate'
import { deepClone } from '@/functions/utils'
export default {
  name: 'CwpGenericForm',
  components: {
    CwpLink,
    CwpIcon,
    CwpDatePicker,
    CwpAddressLookup,
    CwpMessages,
    CwpModal,
    RvForm,
    RvInput,
    RvSelect,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    persistHeading: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: 'Submit',
    },
  },
  metaInfo() {
    return {
      // add rea convestion script if id is in the response
      script: [
        this.container?.reaTrackingID
          ? {
              id: 'rea',
              src: 'https://leads.media-tools.realestate.com.au/conversions.js',
              pbody: true,
            }
          : {},
      ],
    }
  },
  data() {
    return {
      modal: false,
      showThankYouSingle: false,
      showError: false,
      api: `${process.env.VUE_APP_API_URL}${
        process.env.VUE_APP_DEV_PROJECT ? process.env.VUE_APP_DEV_PROJECT : window.location.pathname.split('/')[1]
      }/mvc/`,
      loading: false,
      fields: [],
    }
  },
  computed: {
    isFloating() {
      return this.container?.displayType === 'floating'
    },
    hasPostcode() {
      const postcode = this.container.fields.filter(el => el.name === 'Postcode')
      return postcode.length !== 0
    },
    ...mapState({
      breakpoints: state => state.settings.breakpoints,
      integrations: state => state.settings.integrations,
      csrf: state => state.site.csrf,
    }),
  },
  watch: {
    $route() {
      this.resetForm()
    },
    modal(newVal) {
      if (!newVal) {
        this.resetForm()
      }
    },
  },
  mounted() {
    if (this.container.fields) {
      const fields = deepClone(this.container.fields)
      buildRules(this.container.formName, fields)
      this.fields = fields
    }
  },
  methods: {
    formSuccess(formName, formValues) {
      this.$fbq.track('Lead')
      if (this.container.reaTrackingID && window.REA) {
        REA.track_conversion(`${this.container.reaTrackingID}`)
      }
      this.showThankYouSingle = true
      this.$gtmTrack.formSuccess(formName, formValues)
    },
    updatePostcode(postcode, formName) {
      // update postcode fieldValue if it exists
      if (this.hasPostcode) {
        this.$refs[formName + '-Postcode'].fieldValue = postcode
      }
    },
    resetForm() {
      this.showThankYouSingle = false
      this.showError = false
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.cwp-generic-form {
  @include grid(12);

  &__wrapper {
    grid-column: span 12;

    @include media('>=large') {
      grid-column: 3 / span 8;
    }
  }

  &__header {
    text-align: center;
    margin: 16px 0;
    grid-column: span 12;

    @include media('>=large') {
      grid-column: 3 / span 8;
    }
  }

  &__display-type--floating &__header {
    text-align: left;
    grid-column: span 12;
    margin: 0;
  }

  &__heading {
    margin: 8px 0;
  }

  &__display-type--floating &__heading {
    margin: 0 40px 8px 0;
  }

  &__display-type--floating .rv-form__buttons {
    @include media('<large') {
      text-align: left;
    }
  }

  &__description {
    margin: 8px 0;
  }

  &__floating-button {
    $size: 40px;
    $halfSize: $size / 2;

    position: fixed;
    z-index: 9;
    top: calc(50vh - #{$halfSize});
    right: 0;
    height: $size;
    width: $size;
    background-color: var(--primary-color);
    color: white;
  }
}
</style>
