<template>
  <section class="cwp-project-card">
    <div class="cwp-project-card__image-container--dummy"></div>
    <div class="cwp-project-card__image-container">
      <div class="cwp-project-card__project-image-container">
        <img class="cwp-project-card__project-image" :src="`${baseUrl}${projectData.image}`" :alt="projectData.name" />
        <div class="cwp-project-card__button-container-desktop">
          <cwp-link v-if="projectData.url" class="button secondary" @click="() => selectProject(projectData)">
            Overview
          </cwp-link>
          <cwp-link
            v-if="projectData.websiteUrl"
            class="button secondary"
            :href="projectData.websiteUrl"
            target="_blank"
          >
            Visit website
          </cwp-link>
        </div>
      </div>
      <div>
        <img class="cwp-project-card__logo" :src="`${baseUrl}${projectData.logo}`" :alt="projectData.name" />
      </div>
    </div>
    <div class="cwp-project-card__info-container">
      <div class="cwp-project-card__description" v-html="projectData.description"></div>
      <div>
        <div class="cwp-project-card__info-item cwp-project-card__info-item--align">
          <cwp-icon name="location.svg" alt="Location marker" class="cwp-project-card__icon" />
          {{ projectData.location }}
        </div>
        <div class="cwp-project-card__info-item cwp-project-card__info-item--align">
          <cwp-icon name="building.svg" alt="Location marker" class="cwp-project-card__icon" />
          {{ projectData.type }}
        </div>
        <div class="cwp-project-card__info-item">
          <CwpStatusMarker :status="projectData.status" />
        </div>
      </div>
      <div class="cwp-project-card__button-container-mobile">
        <cwp-link v-if="projectData.url" class="button secondary" @click="() => selectProject(projectData)">
          Overview
        </cwp-link>
        <cwp-link v-if="projectData.websiteUrl" class="button secondary" :href="projectData.websiteUrl" target="_blank">
          Visit website
        </cwp-link>
      </div>
    </div>
  </section>
</template>

<script>
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpLink from '@/components/CwpLink/CwpLink.vue'

import CwpStatusMarker from './CwpStatusMarker.vue'

export default {
  name: 'CwpProjectCard',
  components: { CwpIcon, CwpStatusMarker, CwpLink },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    selectProject: {
      type: Function,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
// Heights for large break points (to help with transition)
// $logo-height needs to be manually entered into calc function
$project-image-height: 200px;
$logo-height: 140px;
$image-container-height: $project-image-height + $logo-height;

.cwp-project-card {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;

  &__image-container {
    transition: height 0.3s ease-in;
    width: 100%;

    @include media('>=large') {
      position: absolute;
      height: $image-container-height;
    }

    &--dummy {
      display: none;

      @include media('>=large') {
        display: block;
        height: $image-container-height;
      }
    }
  }

  &__project-image-container {
    transition: height 0.3s ease-in;
    position: relative;
    display: flex;

    @include media('>=medium') {
      height: $project-image-height;
    }
  }

  &__button-container-desktop,
  &__button-container-mobile {
    .button {
      margin: 0;
      width: 100%;

      @include media('>=large') {
        width: auto;
        min-width: 130px;
      }
    }
  }

  &__button-container-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    justify-content: center;

    .button {
      margin-top: 20px;
    }

    @include media('>=large') {
      display: none;
      flex-direction: row;
    }
  }

  &__button-container-desktop {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.25s ease-in;
    display: none;

    .button:first-child {
      margin-bottom: 20px;
    }

    @include media('>=large') {
      display: flex;
    }
  }

  &__project-image,
  &__logo {
    width: 100%;
  }

  &__project-image {
    object-fit: cover;
    transition: height 0.3s ease-in;

    @include media('>=medium') {
      height: $project-image-height;
    }
  }

  &__logo {
    object-fit: contain;
    height: 160px;
    background-color: #fff;
    padding: 10px;

    @include media('>=small') {
      height: 180px;
    }

    @include media('>=medium') {
      height: $logo-height;
    }
  }

  &__info-container {
    padding: 0 20px 5px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__description {
    flex-grow: 1;
    display: flex;
    align-items: center;
    p {
      margin: 0 0 20px;
    }
  }

  &__info-item {
    margin-bottom: 15px;
    font-weight: var(--strong-text-font-weight);
    text-transform: uppercase;

    &--align {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  &:hover {
    @include media('>=large') {
      .cwp-project-card__button-container-desktop {
        opacity: 1;
        transition: opacity 0.35s ease-out;
      }

      .cwp-project-card__image-container {
        height: 100%;
        transition: height 0.3s ease-out;
      }

      .cwp-project-card__project-image-container {
        // Must match $logo-height
        height: calc(100% - 140px);
        transition: height 0.3s ease-out;
      }

      .cwp-project-card__project-image {
        height: 100%;
        transition: height 0.3s ease-out;
      }
    }
  }
}
</style>
