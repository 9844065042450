<template>
  <ValidationProvider
    v-if="field"
    v-slot="{ errors, classes }"
    :rules="field.validation"
    :name="field.name"
    :custom-messages="field.messages"
  >
    <div :class="{ ...classes, 'rv-input': true, 'rv-address-lookup': true }">
      <div v-if="field.tooltip" class="rv-form__tooltip">
        <div v-if="field.tooltip.icon && field.tooltip.icon.name" class="rv-form__tooltip-icon">
          <span :class="`${field.tooltip.icon.name}`"></span>
        </div>
        <div v-if="field.tooltip.icon && field.tooltip.icon.url" class="rv-form__icon">
          <img :src="field.tooltip.icon.url" :alt="`${field.label} icon`" />
        </div>
        <div v-if="field.tooltip.content" class="rv-form__tooltip-content">
          {{ field.tooltip.content }}
        </div>
      </div>
      <template v-if="field.icon">
        <div v-if="field.icon.name" class="rv-form__icon">
          <span :class="`${field.icon.name}`"></span>
        </div>
        <div v-if="field.icon.url" class="rv-form__icon">
          <img :src="field.icon.url" :alt="`${field.label} icon`" />
        </div>
      </template>
      <label v-if="field.label" :for="field.id" :class="`rv-form__label`">
        {{ field.label }}
        <sup v-if="field.validation ? field.validation.required : ''">*</sup>
        <sup v-else-if="classes.required && classes.invalid">*</sup>
      </label>
      <input
        :id="field.id"
        ref="address"
        class="cwp-address-lookup__input"
        type="text"
        :data-test="field.id"
        :value="fieldValue"
        :class="`rv-form__field`"
        :name="field.name"
        :placeholder="field.placeholder"
        :aria-describedby="ariaDescribedby"
        autocomplete="new-password"
        @input="getPrediction"
      />
      <div
        v-if="predictions"
        :class="
          predictions.length >= 1
            ? 'cwp-address-lookup__predictions cwp-address-lookup__predictions--open'
            : 'cwp-address-lookup__predictions'
        "
      >
        <div v-for="(prediction, index) in predictions" :key="index" class="cwp-address-lookup__prediction">
          <div
            tabindex="0"
            @click="setAddress(prediction.description)"
            @keyup.enter="setAddress(prediction.description)"
          >
            {{ prediction.description }}
          </div>
        </div>
      </div>
      <span v-if="error" class="cwp-address-lookup__error">{{ friendlyError }}</span>
      <slot name="messages" :errors="errors" />
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'CwpAddressLookup',
  props: {
    field: {
      type: Object,
      default: () => {},
      required: true,
    },
    ariaDescribedby: {
      type: [String, Boolean],
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    classList: {
      type: Object,
      default: () => {},
    },
    formName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      error: '',
      predictions: [],
      fieldValue: '',
    }
  },
  computed: {
    friendlyError() {
      if (this.error === 'ZERO_RESULTS') {
        return 'No results available'
      }
      if (this.error === 'MISSING_VALUE') {
        return 'Could not find postcode for address'
      }
      return ''
    },
  },
  methods: {
    updateFieldValue() {
      this.$emit('input', this.formName, this.field.name, this.fieldValue)
    },
    updatePostcodeValue(postcode) {
      this.$emit('input', this.formName, 'Postcode', postcode)
      this.$emit('postcode', postcode, this.formName)
      this.$el.querySelector('input').focus()
    },
    setAddress(address) {
      this.getAddressDetails(address)
      this.predictions = []
    },
    getPrediction(address) {
      this.fieldValue = address.target.value
      this.updateFieldValue()
      if (this.fieldValue && this.fieldValue.length >= 4) {
        let displaySuggestions = (predictions, status) => {
          this.predictions = predictions
        }
        var service = new google.maps.places.AutocompleteService()
        service.getPlacePredictions(
          {
            input: this.fieldValue,
            componentRestrictions: { country: 'aus' },
            types: ['(cities)'],
            location: new google.maps.LatLng({ lat: -37.818, lng: 145.252 }),
            radius: 100,
          },
          displaySuggestions
        )
      } else {
        this.predictions = []
      }
    },
    getAddressDetails(selectedAddress) {
      const geocoder = new google.maps.Geocoder()
      const address = selectedAddress
      return new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status == 'OK') {
            const acceptedValues = ['postal_code', 'locality']
            let filteredResults = {}
            results[0].address_components.forEach(el => {
              acceptedValues.forEach(value => {
                if (el.types.includes(value)) {
                  filteredResults = { ...filteredResults, [value]: el.long_name }
                }
              })
            })
            this.updatePostcodeValue(filteredResults.postal_code || '')
            this.fieldValue = filteredResults.locality || ''
            this.updateFieldValue()
            this.error = ''
            resolve(filteredResults)
          } else {
            this.error = status
            this.time = ''
            console.log('Geocode was not successful for the following reason: ' + status)
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
.rv-address-lookup {
  position: relative;
}
.cwp-address-lookup__predictions {
  position: absolute;
  top: 69px;
  border: solid 1px #979797;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  font-size: 16px;
  padding: 9px;
  margin-bottom: 8px;
  display: block;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  background: #fff;
  max-height: 0;
  overflow-y: scroll;
  z-index: 2;
  &--open {
    pointer-events: inherit;
    opacity: 1;
    max-height: 200px;
  }
  .cwp-address-lookup__prediction {
    cursor: pointer;
    padding: 10px 0;
  }
}
</style>
