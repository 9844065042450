<template>
  <transition name="cwp-modal">
    <div
      v-show="value"
      :class="['cwp-modal', size ? `cwp-modal--${size}` : '', removePadding ? 'cwp-modal--no-padding' : '']"
      aria-modal="true"
      role="dialog"
      @click.prevent="$emit('input', false)"
      @keyup.enter="$emit('input', false)"
    >
      <div class="cwp-modal__tab-start" tabindex="0" @focus.prevent="focusLast" />
      <div class="cwp-modal__inner" @click.stop @keyup.enter.stop>
        <div class="cwp-modal__close">
          <cwp-icon
            ref="close"
            alt="Close dialog"
            interactive
            name="close.svg"
            tabindex="0"
            @click="$emit('input', false)"
          />
        </div>
        <div class="cwp-modal__body">
          <slot />
        </div>
      </div>
      <div class="cwp-modal__tab-end" tabindex="0" @focus.prevent="focusFirst" />
    </div>
  </transition>
</template>

<script>
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'

export default {
  name: 'CwpModal',
  components: {
    CwpIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    removePadding: {
      type: Boolean,
      default: false,
    },
    size: {
      default: '',
      validator(value) {
        return ['', 'large'].includes(value)
      },
    },
  },
  data() {
    return {
      scrollY: 0,
      top: '',
      lastFocusableElement: null,
    }
  },
  watch: {
    value(newValue) {
      this.$nextTick(() => {
        if (newValue) {
          // Fix scrolling position when modal is open
          this.scrollY = window.scrollY
          this.top = `-${this.scrollY}px`

          // Update store to modify cwo-app classes and styles
          this.$store.dispatch('settings/updateModal', { open: newValue, top: this.top })

          this.focusFirst()
          // Trap focus inside modal when open
          document.addEventListener('keyup', this.handleClose)
        } else {
          this.top = ''
          // Update store to modify cwo-app classes and styles
          this.$store.dispatch('settings/updateModal', { open: newValue, top: this.top })

          this.$nextTick(() => {
            window.scrollTo(0, this.scrollY || 0)
          })

          document.removeEventListener('keyup', this.handleClose)
        }
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.lastFocusableElement = this.$el.querySelector('.cwp-link:last-of-type')
    })
  },
  methods: {
    focusFirst() {
      if (this.$refs.close.$el) this.$refs.close.$el.focus()
    },
    focusLast() {
      if (this.lastFocusableElement) this.lastFocusableElement.focus()
    },
    // Closes modal on esc key up
    handleClose($event) {
      if ($event.keyCode == 27) {
        this.$emit('input', false)
      }
    },
  },
}
</script>

<style lang="scss">
.cwp-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.75);

  &-enter-active,
  &-leave-active {
    transition: opacity 250ms $easeOutQuad;
  }

  &-enter,
  &-leave-active {
    opacity: 0;
  }

  &__inner {
    position: fixed;
    background-color: var(--background-color);
    overflow: auto;
    margin: auto;
    max-width: 600px;
    width: calc(100vw - 32px);
    max-height: 80vh;

    &::-webkit-scrollbar {
      display: block;
      border-radius: 8px;
      background: none;
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: lightgray;
      border-radius: 8px;
      width: 8px;
      height: 8px;
    }

    @include media('>=large') {
      padding: 24px;
    }

    .cwp-modal-enter-active &,
    .cwp-modal-leave-active & {
      transition: transform 250ms $easeOutQuad, opacity 250ms $easeOutQuad;
      transform-origin: bottom;
    }

    .cwp-modal-enter &,
    .cwp-modal-leave-active & {
      transform: translate(0, 20px);
      opacity: 0;
    }
  }

  &__body {
    padding: 16px;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    z-index: 1;

    @include media('>=large') {
      top: 24px;
      right: 24px;
    }
  }

  .cwp-generic-form {
    grid-gap: 16px 8px;
    grid-template-columns: auto;
  }

  &--large {
    .cwp-modal__inner {
      max-width: 1200px;
    }
  }

  &--no-padding {
    .cwp-modal__body {
      padding: 0;
    }

    .cwp-modal__inner {
      padding: 0;
    }
  }
}
</style>
