<template>
  <section
    v-if="container && container.items"
    class="cwp-latest-news"
    :class="`cwp-latest-news__cards--${container.items.length}`"
  >
    <div class="grid-container padding-y">
      <div class="cwp-latest-news__grid">
        <div v-if="container.heading || container.description" class="cwp-latest-news__description">
          <component :is="container.categoryHeadingStyle" v-if="container.heading" class="cwp-latest-news__heading">{{
            container.heading
          }}</component>
          <p v-if="container.description" class="cwp-latest-news__description-text">
            {{ container.description }}
          </p>
          <div class="cwp-latest-news__more-articles">
            <cwp-link
              v-if="container.viewMoreArticlesUrl"
              :to="container.viewMoreArticlesUrl"
              button
              class="button primary"
              >View more articles</cwp-link
            >
          </div>
        </div>
        <cwp-news-card v-for="(card, i) in container.items" :key="i" :card="card" />
      </div>
    </div>
  </section>
</template>

<script>
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpNewsCard from '@/components/CwpNewsCard/CwpNewsCard.vue'
export default {
  name: 'CwpLatestNews',
  components: {
    CwpLink,
    CwpNewsCard,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.cwp-latest-news {
  background-color: var(--background-color);

  &__grid {
    @include grid(12, 8px 8px, 16px 24px);
  }

  &__description {
    color: var(--text-color);
    grid-column: span 12;

    @include media('>=large') {
      grid-column: span 4;
      grid-row: span 2;
    }
  }

  &__heading {
    margin: 0;
  }

  &__description-text {
    margin: 0;
  }

  &__more-articles {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .cell {
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: span 4;
    }
  }

  @include media('>=large') {
    &:not(.cwp-latest-news__cards--2) {
      .cell:first-of-type {
        grid-column: span 8;

        .rv-card {
          display: grid;
          grid-gap: 16px;
          grid-template-columns: 6fr 6fr;
        }

        .rv-card__content {
          flex-grow: 0;
        }
      }
    }
  }
}
</style>
