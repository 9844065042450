<template>
  <section class="cwp-location-details">
    <div class="grid-container padding-y">
      <div class="cwp-location-details__grid">
        <div class="cwp-location-details__map">
          <cwp-map title="Location Map" :latitude="container.latitude" :longitude="container.longitude" />
        </div>
        <div class="cwp-location-details__container">
          <component :is="container.headingStyle" v-if="container.heading" class="cwp-location-details__heading">{{
            container.heading
          }}</component>
          <div class="cwp-location-details__address">
            <h5 class="cwp-location-details__header">Address</h5>
            <div>
              <span v-if="container.address">{{ container.address }}, <br /></span>
              <span v-if="container.suburb">{{ container.suburb }}, </span>
              <span v-if="container.state">{{ container.state }} {{ container.postcode }}</span>
            </div>
            <cwp-link
              v-if="container.latitude && container.longitude"
              :href="`https://www.google.com/maps/dir/?api=1&destination=${container.latitude},${container.longitude}`"
              class="cwp-location-details__cta-button button primary"
              target="_blank"
              >Get Directions</cwp-link
            >
          </div>
          <div v-if="container.openingHours" class="cwp-location-details__opening-hours">
            <h5 class="cwp-location-details__header">Opening hours</h5>
            <div v-html="container.openingHours" />
          </div>
          <cwp-link
            v-if="container.buttonText"
            :href="container.buttonUrl"
            class="cwp-location-details__cta-button button primary"
            target="_blank"
            >{{ container.buttonText }}</cwp-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpMap from '@/components/CwpMap/CwpMap.vue'
export default {
  name: 'CwpLocationDetails',
  components: {
    CwpLink,
    CwpMap,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.cwp-location-details {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__map {
    grid-column: span 12;

    @include media('>=large') {
      grid-column: span 8;
      .cwp-map {
        height: 100%;
      }
    }
  }

  &__container {
    grid-column: span 12;

    @include media('>=large') {
      grid-column: span 4;
    }
  }

  &__heading {
    margin: 0;
  }

  &__header {
    margin: 16px 0;
  }

  &__cta-button {
    margin: 16px 0;
  }
}
</style>
