<template>
  <div class="cwp-hubspot-newsletter">
    <cwp-hub-spot-form :container="container" persist-heading />
  </div>
</template>

<script>
import CwpHubSpotForm from '@/components/CwpHubSpotForm/CwpHubSpotForm.vue'
export default {
  name: 'CwpHubSpotNewsletter',
  components: {
    CwpHubSpotForm,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.cwp-hubspot-newsletter {
  background-color: var(--background-color);

  .cwp-generic-form {
    &__wrapper {
      @include media('>=medium') {
        grid-column: span 6;
      }
    }

    &__header {
      text-align: left;
      margin: 0;
      grid-column: span 12;

      @include media('>=medium') {
        grid-column: span 6;
      }
    }

    &__heading {
      margin: 0 0 8px 0;
    }

    &__description {
      margin: 8px 0;
    }
  }
}
</style>
