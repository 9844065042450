var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-y cwp-carousel-container cwp-carousel-container"},[(_vm.slides.length)?_c('rv-carousel',{staticClass:"cwp-carousel-container__carousel",class:{
      'rv-carousel--hide-drag': _vm.slides.length === 1,
      'rv-carousel--overlaid': _vm.container.textLayout === 'overlaid',
      'rv-carousel--below': _vm.container.textLayout !== 'overlaid',
    },attrs:{"name":`carousel-${_vm.container.id}`,"navigation":_vm.slides.length > 1 && !_vm.hideControls,"scrollbar":true,"options":{
      loop: false,
      initialSlide: _vm.slides.length > 2 ? 1 : 0,
      observer: true,
      observeParents: true,
      slidesPerView: 'auto',
      centeredSlides: _vm.centeredSlides,
      spaceBetween: 24,
      autoHeight: false,
      slidesPerColumnFill: 'row',
      scrollbar: {
        el: '.rv-carousel__scrollbar',
        hide: false,
        dragSize: _vm.breakpoints.medium ? 240 : 64,
        draggable: true,
      },
    }},on:{"slideChange":_vm.stopVideo},scopedSlots:_vm._u([{key:"prev",fn:function(){return [_c('cwp-icon',{attrs:{"name":"angle-left.svg"}})]},proxy:true},{key:"next",fn:function(){return [_c('cwp-icon',{attrs:{"name":"angle-right.svg"}})]},proxy:true}],null,false,3026583172)},_vm._l((_vm.slides),function(slide){return _c('div',{key:slide.index,class:{
        'rv-carousel__slide': true,
        'rv-carousel--text-background': slide.displayOverlay,
        'rv-carousel--video': slide.video,
      }},[(slide.video)?[(slide.video)?_c('div',[_c('div',{class:{
              'rv-carousel__video': true,
              'rv-carousel__video--ready': slide.ready,
            }},[_c('div',{attrs:{"data-video-index":slide.index},domProps:{"innerHTML":_vm._s(_vm.youtubeEmbed(slide.video))}}),(slide.image)?_c('img',{staticClass:"rv-carousel__video--image",attrs:{"src":slide.image,"alt":slide.imageAltText}}):_vm._e()])]):_vm._e()]:[(slide.image)?_c('div',{staticClass:"rv-carousel__image",style:(`background-image:url('${slide.image}');`)}):_vm._e()],_c('div',{class:{
          'rv-carousel__details': true,
          'rv-carousel--left': _vm.container.textAlignment === 'left',
          'rv-carousel--center': _vm.container.textAlignment !== 'left',
          'rv-carousel--hide-details': slide.playing,
          [`rv-carousel__text-color--${slide.textColor}`]: true,
        }},[(slide.heading)?_c('h4',{staticClass:"rv-carousel__details-item"},[_vm._v(_vm._s(slide.heading))]):_vm._e(),(slide.description)?_c('span',{staticClass:"rv-carousel__details-item",domProps:{"innerHTML":_vm._s(slide.description)}}):_vm._e()])],2)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }