<template>
  <section class="cwp-news-article">
    <div class="grid-container padding-y">
      <div class="cwp-news-article__grid">
        <div class="cwp-news-article__container">
          <cwp-breadcrumbs v-if="container.displayBreadcrumbs" :container="page" :padding="false" />
          <h1 v-if="container.title" class="cwp-news-article__title">{{ container.title }}</h1>
          <div class="cwp-news-article__meta">
            <div v-if="container.author" class="cwp-news-article__author">
              {{ container.author }}
            </div>
            <div v-if="container.publicationDate" class="cwp-news-article__publication-date">
              {{ formatDate(container.publicationDate) }}
            </div>
            <div class="cwp-news-article__category">{{ container.categoryName }}</div>
            <div class="cwp-news-article__sharer">
              <cwp-sharer />
            </div>
          </div>
          <div
            v-if="container.newsContent"
            class="cwp-news-article__news-content cwp-html-content"
            v-html="container.newsContent"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CwpBreadcrumbs from '@/components/CwpBreadcrumbs/CwpBreadcrumbs.vue'
import CwpSharer from '@/components/CwpSharer/CwpSharer.vue'
import { formatDate, responsiveTables, responsiveEmbed } from '@/functions/utils'
import { mapState } from 'vuex'
export default {
  name: 'CwpNewsArticle',
  components: {
    CwpBreadcrumbs,
    CwpSharer,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      overflowControllers: [],
    }
  },
  computed: {
    ...mapState({
      page: state => state.site.page,
    }),
  },
  mounted() {
    this.overflowControllers = responsiveTables(this.$el)
    responsiveEmbed(this.$el)
  },
  destroyed() {
    this.overflowControllers.map(controller => controller.destroy())
  },
  methods: {
    formatDate,
  },
}
</script>

<style lang="scss">
.cwp-news-article {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__container {
    color: var(--text-color);
    grid-column: span 12;

    @include media('>=large') {
      grid-column: 3 / span 8;
    }
  }

  &__heading {
    margin: 0;
  }

  &__meta {
    display: flex;
    margin-bottom: 24px;
    flex-direction: column;

    @include media('>=medium') {
      flex-direction: row;
    }
  }

  &__author {
    font-weight: 700;
    margin-right: 16px;
    margin-bottom: 8px;
  }

  &__publication-date {
    margin-right: 16px;
    margin-bottom: 8px;
  }

  &__category {
    margin-right: 16px;
    margin-bottom: 8px;
  }

  &__sharer {
    display: flex;
    flex-grow: 1;
    margin-bottom: 8px;

    @include media('>=medium') {
      justify-content: flex-end;
    }
  }

  &__image {
    position: absolute;
    object-fit: cover;
    object-position: center;
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
  }
}
</style>
