<template>
  <section class="cwp-accordion-container">
    <div class="grid-container padding-y">
      <div class="cwp-accordion-container__grid">
        <template v-if="categoryItems.length > 1">
          <div class="cwp-accordion-container__side-menu-container">
            <ul class="cwp-accordion-container__side-menu">
              <li
                v-for="(category, i) in categoryItems"
                :id="`accordion-control-${container.id}-${i}`"
                :key="i"
                class="cwp-accordion-container__side-menu-item"
                :class="{ 'cwp-accordion-container__side-menu-item--active': category.expanded }"
                tabindex="0"
                role="tab"
                :aria-expanded="category.expanded"
                :aria-selected="category.expanded"
                :aria-controls="`accordion-content-${container.id}-${i}`"
                @click="handleToggle(i)"
                @keyup.enter="handleToggle(i)"
              >
                {{ category.name }}
              </li>
            </ul>
          </div>
          <div class="cwp-accordion-container__spacer" />
        </template>
        <div
          :class="{ 'cwp-accordion-container__accordion-container--centered': categoryItems.length <= 1 }"
          class="cwp-accordion-container__accordion-container"
        >
          <rv-expansion-panel
            v-for="(category, i) in categoryItems"
            :key="i"
            v-model="category.expanded"
            class="cwp-accordion-container__accordion"
          >
            <template #toggle>
              <div class="cwp-accordion-container__toggle" @click="handleToggle(i)" @keyup.enter="handleToggle(i)">
                <div class="rv-expansion-panel__label" v-html="category.name" />
                <span class="rv-expansion-panel__chevron" />
              </div>
            </template>
            <template #default>
              <rv-expansion-panel
                v-for="(accordion, i2) in category.accordionItems"
                :id="`accordion-content-${container.id}-${i}`"
                :key="`${i}-${i2}`"
                v-model="accordion.expanded"
                :label="accordion.heading"
                class="cwp-accordion-container__accordion cwp-accordion-container__accordion--nested"
                :aria-labelledby="`accordion-control-${container.id}-${i}`"
              >
                <template #toggle>
                  <div
                    :id="`accordion-control-${container.id}-${i}`"
                    class="cwp-accordion-container__toggle cwp-accordion-container__toggle--nested"
                    @click="handleNestedToggle(i, i2)"
                    @keyup.enter="handleNestedToggle(i, i2)"
                  >
                    <div class="rv-expansion-panel__label" v-html="accordion.heading" />
                    <span class="rv-expansion-panel__plus" />
                  </div>
                </template>
                <div
                  class="cwp-accordion-container__content cwp-accordion-container__content--nested cwp-html-content"
                  v-html="accordion.description"
                />
              </rv-expansion-panel>
            </template>
          </rv-expansion-panel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { RvExpansionPanel } from '@revium/components'
import { responsiveTables, responsiveEmbed } from '@/functions/utils'
import { mapState } from 'vuex'
export default {
  name: 'CwpAccordionContainer',
  components: {
    RvExpansionPanel,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categoryItems: [],
      overflowControllers: [],
    }
  },
  computed: {
    ...mapState({
      breakpoints: state => state.settings.breakpoints,
    }),
  },
  watch: {
    // If screen size goes to desktop, check that at least one category accordion is open
    'breakpoints.large'(newVal) {
      if (newVal) {
        if (this.categoryItems.filter(category => category.expanded).length === 0) {
          this.categoryItems[0].expanded = true
          this.updateWatchers()
        }
      }
    },
  },
  mounted() {
    // Set expanded properties for each accordion to bind to v-model
    this.categoryItems = this.container.categoryItems.map((category, i) => ({
      ...category,
      // First accordion should be expanded on desktop, or if there is only one category
      expanded: i === 0 && (this.breakpoints.large || this.container.categoryItems.length === 1),
      accordionItems: category.accordionItems.map(accordion => ({
        expanded: false,
        ...accordion,
      })),
    }))
  },
  destroyed() {
    this.destroyWatchers()
  },
  methods: {
    // Closes one accordion, or opens one while closing the rest.
    handleToggle(i) {
      if (this.categoryItems[i].expanded) {
        // On large devices one must always be open
        if (!this.breakpoints.large) this.categoryItems[i].expanded = false
      } else {
        this.categoryItems.forEach((category, i2) => {
          if (i === i2) {
            setTimeout(() => {
              this.categoryItems[i2].expanded = true
            }, 100)
          } else {
            this.categoryItems[i2].expanded = i === i2
          }
        })
      }
    },
    // Closes nested one accordion, or opens one while closing the rest.
    handleNestedToggle(i, i2) {
      if (this.categoryItems[i].accordionItems[i2].expanded) {
        this.categoryItems[i].accordionItems[i2].expanded = false
      } else {
        this.categoryItems[i].accordionItems.forEach((accordion, i3) => {
          if (i2 === i3) {
            setTimeout(() => {
              this.categoryItems[i].accordionItems[i3].expanded = true
            }, 100)
          } else {
            this.categoryItems[i].accordionItems[i3].expanded = false
          }
        })
        this.updateWatchers()
      }
    },
    updateWatchers() {
      this.destroyWatchers()
      this.$nextTick(() => {
        this.overflowControllers = responsiveTables(this.$el)
        responsiveEmbed(this.$el)
      })
    },
    destroyWatchers() {
      this.overflowControllers.map(controller => controller.destroy())
    },
  },
}
</script>

<style lang="scss">
.cwp-accordion-container {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__side-menu-container {
    display: none;
    grid-column: span 3;

    @include media('>=large') {
      display: block;
    }
  }

  &__side-menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__side-menu-item {
    padding: 16px;
    border-bottom: currentColor solid 1px;
    opacity: 0.6;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 200ms $easeOutQuad;

    &:first-child {
      border-top: currentColor solid 1px;
    }

    &--active {
      opacity: 1;
    }
  }

  &__accordion {
    &--nested {
      border-bottom: currentColor solid 1px;

      @include media('>=large') {
        &:first-child {
          border-top: currentColor solid 1px;
        }
      }
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: currentColor solid 2px;
    cursor: pointer;

    @include media('>=large') {
      display: none;
    }

    &--nested {
      display: flex;
      border-bottom: 0;
      padding: 16px 0 16px 16px;
    }
  }

  &__accordion-container {
    grid-column: span 12;

    @include media('>=large') {
      grid-column: span 8;
    }

    &--centered {
      @include media('>=large') {
        grid-column: 3 / span 8;
      }

      & > .cwp-accordion-container__accordion > .cwp-accordion-container__toggle {
        display: none;
      }
    }
  }

  &__content {
    &--nested {
      padding: 0 16px 24px 16px;

      & > *:first-child {
        margin-top: 0;
      }

      & > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  .rv-expansion-panel-enter-active,
  .rv-expansion-panel-leave-active {
    transition: all 500ms $easeInOutQuad;
  }
}
</style>
