var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-y cwp-logo-container"},[_c('div',{staticClass:"grid-container"},[(_vm.container.logoItems.length)?_c('rv-carousel',{staticClass:"cwp-logo-container__carousel",class:{ 'rv-carousel--hide-drag': _vm.hideDrag },attrs:{"name":`carousel-${_vm.container.id}`,"navigation":false,"scrollbar":true,"options":{
        loop: false,
        initialSlide: 0,
        slidesPerView: 2,
        centeredSlides: false,
        centeredSlidesBounds: true,
        centerInsufficientSlides: true,
        spaceBetween: 24,
        autoHeight: false,
        scrollbar: {
          el: '.rv-carousel__scrollbar',
          hide: false,
          dragSize: _vm.breakpoints.medium ? 240 : 64,
          draggable: true,
        },
        breakpoints: {
          640: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        },
      }}},_vm._l((_vm.container.logoItems),function(slide,i){return _c('div',{key:i,class:`rv-carousel__slide rv-carousel__slide--${i}`},[_c('a',{attrs:{"href":slide.imageUrl,"title":slide.imageAltText,"target":"_blank"}},[_c('img',{staticClass:"cwp-logo-container__image",attrs:{"src":slide.image,"alt":slide.imageAltText}})])])}),0):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }