<template>
  <section class="grid-container">
    <rv-tabs :tabs="container.tabs" />
  </section>
</template>

<script>
export default {
  name: 'CwpTabContainer',
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
}
</script>
