<template>
  <div>
    <rv-banner
      class="rv-banner--slim"
      :picture="picture"
      :content-position="container.textAlignment"
      :class="{ 'rv-banner--overlay': container.displayOverlay }"
      :style="background"
    >
      <template #content>
        <div class="rv-banner__content">
          <div class="rv-banner__content-box">
            <template v-if="container.heading">
              <div class="rv-banner__heading">
                <h1 v-if="container.h1Heading">{{ container.heading }}</h1>
                <h2 v-else>{{ container.heading }}</h2>
              </div>
            </template>
            <template v-if="container.description">
              <div class="rv-banner__sub-heading">
                <div v-html="container.description" />
              </div>
            </template>
            <template v-if="container.buttonUrl1 || container.buttonUrl2">
              <div class="rv-banner__blurb">
                <cwp-link
                  v-if="container.buttonUrl1"
                  :to="container.buttonUrl1"
                  :target="container.buttonOpenNewTab1 ? '_blank' : ''"
                  button
                  class="rv-banner__link--1"
                >
                  {{ container.buttonText1 }}
                </cwp-link>
                <cwp-link
                  v-if="container.buttonUrl2"
                  :to="container.buttonUrl2"
                  :target="container.buttonOpenNewTab2 ? '_blank' : ''"
                  button
                  class="rv-banner__link--2"
                >
                  {{ container.buttonText2 }}
                </cwp-link>
              </div>
            </template>
          </div>
        </div>
      </template>
    </rv-banner>
  </div>
</template>

<script>
import { RvBanner } from '@revium/components'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
export default {
  name: 'CwpSlimBanner',
  components: {
    CwpLink,
    RvBanner,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  computed: {
    picture() {
      if (this.container.backgroundType === 'color') return
      return {
        defaultSrc: this.container.desktopImage,
        alt: this.container.imageAltText,
        sources: [
          {
            size: 'large',
            srcset: this.container.desktopImage ?? '',
          },
          {
            size: 'small',
            srcset: this.container.mobileImage ?? '',
          },
        ],
      }
    },
    background() {
      if (this.container.backgroundType === 'image') return
      return {
        backgroundColor: this.container.backgroundColor,
      }
    },
  },
}
</script>
