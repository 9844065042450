<template>
  <section class="cwp-runway-maps padding-y">
    <div id="runway-container" class="runway"></div>
  </section>
</template>

<script>
export default {
  name: 'CwpRunwayMaps',
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      $head: null,
      $dependencies: null,
      $runwayAssets: null,
    }
  },
  mounted() {
    this.$head = document.getElementsByTagName('head')[0]
    this.$dependencies = this.$head.querySelector('#runway-dependencies')
    this.$runwayAssets = this.$head.querySelector('#runway-assets')

    if (this.$dependencies || this.$runwayAssets) window.location.reload()

    if (this.$dependencies === null && this.container.dependencySnippet) {
      this.$dependencies = document.createElement('div')
      this.$dependencies.id = 'runway-dependencies'
      const embedFragment = document.createRange().createContextualFragment(this.container.dependencySnippet)
      this.$dependencies.append(embedFragment)
      this.$head.append(this.$dependencies)
    }

    if (this.$runwayAssets === null && this.container.runwaySnippet) {
      const $ngEl = document.createElement('runway-amenities-app')
      document.getElementById('runway-container').appendChild($ngEl)

      this.$nextTick(() => {
        this.$runwayAssets = document.createElement('div')
        this.$runwayAssets.id = 'runway-assets'
        const embedFragment = document.createRange().createContextualFragment(this.container.runwaySnippet)
        this.$runwayAssets.append(embedFragment)
        this.$head.append(this.$runwayAssets)
      })
    }

    // The map code sets the hash to the first tab (regardless of what's in the URL), so we need to set the hash after map has loaded
    if (this.container.defaultMode) {
      setTimeout(() => {
        location.hash = this.container.defaultMode
      }, 1000)
    }
  },
  unmounted() {
    if (this.$runwayAssets) this.$head.removeChild(this.$runwayAssets)
    if (this.$dependencies) this.$head.removeChild(this.$dependencies)
  },
}
</script>

<style lang="scss">
.cwp-runway-maps {
  padding-left: 16px;
  padding-right: 16px;

  @include media('>=medium') {
    padding-left: 32px;
    padding-right: 32px;
  }
}
</style>
