<template>
  <div class="cwp-slim-carousel-banner-container cwp-carousel-container">
    <rv-carousel
      v-if="slides.length"
      class="rv-carousel--slim cwp-carousel-container__carousel"
      :name="`carousel-${container.id}`"
      :navigation="slides.length > 1"
      :pagination="true"
      :scrollbar="false"
      :options="{
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        observer: true,
        observeParents: true,
        slidesPerView: '1',
        centeredSlides: true,
        slidesPerColumnFill: 'row',
      }"
      :class="{
        'rv-carousel--hide-drag': slides.length === 1,
        'rv-carousel--overlaid': container.textLayout === 'overlaid',
        'rv-carousel--below': container.textLayout !== 'overlaid',
      }"
    >
      <div
        v-for="slide in slides"
        :key="slide.index"
        :class="{
          'rv-carousel__slide': true,
          'rv-carousel--text-background': slide.displayOverlay,
        }"
      >
        <rv-banner
          class="rv-banner--slim"
          :class="[`guid-${slide.id}`, { 'rv-banner--overlay': slide.displayOverlay }]"
          :picture="getPicture(slide)"
          :content-position="slide.textAlignment"
          :style="getBackground(slide)"
        >
          <template #content>
            <div class="rv-banner__content">
              <div class="rv-banner__content-box">
                <template v-if="slide.heading">
                  <div class="rv-banner__heading">
                    <h1 v-if="slide.h1Heading">{{ slide.heading }}</h1>
                    <h2 v-else>{{ slide.heading }}</h2>
                  </div>
                </template>
                <template v-if="slide.description">
                  <div class="rv-banner__sub-heading">
                    <div v-html="slide.description" />
                  </div>
                </template>
                <template v-if="slide.buttonUrl1 || slide.buttonUrl2">
                  <div class="rv-banner__blurb">
                    <cwp-link
                      v-if="slide.buttonUrl1"
                      :to="slide.buttonUrl1"
                      :target="slide.buttonOpenNewTab1 ? '_blank' : ''"
                      button
                      class="rv-banner__link--1"
                    >
                      {{ slide.buttonText1 }}
                    </cwp-link>
                    <cwp-link
                      v-if="slide.buttonUrl2"
                      :to="slide.buttonUrl2"
                      :target="slide.buttonOpenNewTab2 ? '_blank' : ''"
                      button
                      class="rv-banner__link--2"
                    >
                      {{ slide.buttonText2 }}
                    </cwp-link>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </rv-banner>
      </div>
      <template #prev>
        <cwp-icon name="angle-left.svg" />
      </template>
      <template #next>
        <cwp-icon name="angle-right.svg" />
      </template>
    </rv-carousel>
  </div>
</template>

<script>
import { RvCarousel, RvBanner } from '@revium/components'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpLink from '@/components/CwpLink/CwpLink.vue'

export default {
  name: 'CwpSlimCarouselBannerContainer',
  components: {
    CwpIcon,
    CwpLink,
    RvCarousel,
    RvBanner,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      slides: [],
    }
  },
  mounted() {
    this.slides = this.container.carouselItems.map((slide, index) => ({
      ...slide,
      index,
    }))
  },
  methods: {
    getBackground(slide) {
      if (slide.backgroundType === 'image') return
      return {
        backgroundColor: slide.backgroundColor,
      }
    },
    getPicture(slide) {
      if (slide.backgroundType === 'color') return
      return {
        defaultSrc: slide.desktopImage,
        alt: slide.imageAltText,
        sources: [
          {
            size: 'large',
            srcset: slide.desktopImage ?? '',
          },
          {
            size: 'small',
            srcset: slide.mobileImage ?? '',
          },
        ],
      }
    },
  },
}
</script>
<style lang="scss">
.cwp-slim-carousel-banner-container {
  .swiper-pagination-bullets {
    margin: auto 0;
    right: 0;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    left: unset;
    width: unset;
    bottom: unset;

    .swiper-pagination-bullet {
      display: block;
      margin: 8px;
      width: 10px;
      height: 10px;
      opacity: 0.5;
      background: white;

      &-active {
        opacity: 1;
      }
    }
  }
  .rv-carousel {
    &__slide {
      max-width: unset;
    }

    &__navigation {
      position: absolute;
      top: unset;
      left: unset;
      bottom: -28px;

      &--next {
        right: 26px;

        @include media('>=medium') {
          right: 48px;
        }
        .cwp-icon {
          left: 2px;
        }
      }

      &--prev {
        right: 74px;

        @include media('>=medium') {
          right: 104px;
        }

        .cwp-icon {
          right: 0;
        }
      }

      &--disabled {
        opacity: 50%;
      }
    }
  }

  .rv-banner {
    &--slim {
      min-height: 220px;
      height: 100%;
    }

    &__link--1 {
      margin-bottom: 0;
    }

    &__content {
      display: flex;
      margin-bottom: 32px;
      margin-right: 10px;

      @include media('>=large') {
        margin-right: auto;
      }
    }

    &--content {
      &-left {
        & .rv-banner__content {
          justify-content: left;
          align-items: flex-start;
          text-align: left;
        }
      }

      &-center {
        & .rv-banner__content {
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }

      &-right {
        & .rv-banner__content {
          justify-content: end;
          align-items: end;
          text-align: left;
        }
      }
    }
  }
}
</style>
