<template>
  <div class="cwp-status-marker">
    <span :class="`cwp-status-marker__icon cwp-status-marker__icon-${status.replace(/ /g, '-')}`"></span>
    <span class="cwp-status-marker__title">{{ status }}</span>
  </div>
</template>

<script>
export default {
  name: 'CwpStatusMarker',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.cwp-status-marker {
  display: flex;
  align-items: center;

  &__icon {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 5px;

    @include media('>=medium') {
      width: 15px;
      height: 15px;
    }

    &-complete {
      background-color: var(--primary-color);
    }

    &-in-progress {
      background-color: var(--secondary-color);
    }

    &-coming-soon {
      background-color: var(--tertiary-color);
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 12px;

    @include media('>=medium') {
      font-size: initial;
    }
  }
}
</style>
