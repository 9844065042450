<template>
  <div class="cwp-map">
    <cwp-loader :loading="loading" class="cwp-loader--absolute" />
    <div class="cwp-map__frame" height="300" width="100%"></div>
  </div>
</template>

<script>
import { MarkerClusterer } from '@googlemaps/markerclusterer'

import CwpLoader from '@/components/CwpLoader/CwpLoader.vue'

export default {
  name: 'CwpMap',
  components: {
    CwpLoader,
  },
  props: {
    locations: {
      type: Array,
      required: true,
    },
    state: {
      type: String,
      default: null,
    },
    selectProject: {
      type: Function,
      default: () => {},
    },
    showInfoWindows: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      map: null,
      markers: [],
      bounds: null,
      markerClusterer: null,
      infoWindow: null,
      loading: true,
      colors: {
        complete: '',
        in_progress: '',
        coming_soon: '',
      },
    }
  },
  watch: {
    locations() {
      if (window.google && this.map) {
        this.markers.forEach(marker => marker.setMap(null))
        this.markerClusterer && this.markerClusterer.removeMarkers(this.markers)
        this.markers = []
        this.locations.forEach(location => this.createPin(location))
        this.markerClusterer && this.markerClusterer.addMarkers(this.markers)
        if (this.locations.length) {
          this.bounds = new google.maps.LatLngBounds()
          for (var i = 0; i < this.markers.length; i++) {
            this.bounds.extend(this.markers[i].getPosition())
          }
          this.map.fitBounds(this.bounds)
        }
      }
    },
  },
  mounted() {
    this.colors = {
      complete: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
      in_progress: getComputedStyle(document.documentElement).getPropertyValue('--secondary-color'),
      coming_soon: getComputedStyle(document.documentElement).getPropertyValue('--tertiary-color'),
    }

    // check google maps has been loaded
    var googleAvailable = setInterval(() => {
      if (window.google) {
        clearInterval(googleAvailable)

        const styledMapType = new google.maps.StyledMapType([
          {
            featureType: 'all',
            elementType: 'all',
            stylers: [
              {
                saturation: -100,
              },
            ],
          },
          { name: 'Styled Map' },
        ])

        const options = Object.assign({
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControlOptions: {
            mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'styled_map'],
          },
          optimized: true,
          minZoom: 4,
          maxZoom: 17,
        })

        this.map = new google.maps.Map(this.$el.querySelector('.cwp-map__frame'), options)

        this.map.mapTypes.set('styled_map', styledMapType)
        this.map.setMapTypeId('styled_map')

        if (this.showInfoWindows) {
          this.infoWindow = new google.maps.InfoWindow({
            content: '',
            ariaLabel: 'Information pop-up relating to the project',
            maxWidth: 400,
            maxHeight: 800,
          })
        }

        this.locations.forEach(location => this.createPin(location))

        const primaryColor = this.colors.complete || '#000'
        this.markerClusterer = new MarkerClusterer({
          markers: this.markers,
          map: this.map,
          renderer: {
            render: function ({ count, position }, stats) {
              const svg = window.btoa(`
  <svg fill="${primaryColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".8" r="70" />
  </svg>`)
              return new google.maps.Marker({
                position,
                icon: {
                  url: `data:image/svg+xml;base64,${svg}`,
                  scaledSize: new google.maps.Size(60, 60),
                },
                label: {
                  text: String(count),
                  color: 'rgba(255,255,255,0.9)',
                  fontSize: '12px',
                },
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              })
            },
          },
        })

        this.bounds = new google.maps.LatLngBounds()
        for (var i = 0; i < this.markers.length; i++) {
          this.bounds.extend(this.markers[i].getPosition())
        }

        google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
          this.map.setZoom(this.map.getZoom() - 1)
          this.map.setCenter(this.bounds.getCenter())
        })

        this.map.fitBounds(this.bounds)

        google.maps.event.addListenerOnce(this.map, 'idle', () => {
          this.loading = false
        })
      }
    }, 1000)
  },
  methods: {
    createPin(location) {
      const markerIcon = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 362.13 362.13" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 362.13 362.13">
                        <g>
                          <path fill="${
                            this.colors.complete
                          }" d="m181.065,0c-75.532,0-136.981,61.45-136.981,136.981 0,31.155 21.475,76.714 63.827,135.411 30.619,42.436 60.744,75.965 62.011,77.372l11.144,12.367 11.144-12.367c1.267-1.406 31.392-34.936 62.011-77.372 42.352-58.697 63.827-104.255 63.827-135.411-0.001-75.531-61.451-136.981-136.983-136.981zm0,316.958c-37.733-44.112-106.981-134.472-106.981-179.977 0-58.989 47.991-106.981 106.981-106.981s106.981,47.992 106.981,106.981c0.001,45.505-69.248,135.865-106.981,179.977z"/>
                          <circle fill="${
                            this.colors[location.status.replace(/ /g, '_')]
                          }" cx="181.065" cy="136.982" r="49.696"/>
                        </g>
                      </svg>`

      const marker = new google.maps.Marker({
        position: { lat: location.Latitude, lng: location.Longitude },
        icon: {
          url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(markerIcon),
          size: new google.maps.Size(35, 35),
          scaledSize: new google.maps.Size(35, 35),
        },
        map: this.map,
      })

      if (this.showInfoWindows && location.url) {
        marker.addListener('click', () => {
          if (!this.infoWindow) return

          if (window) {
            window.viewProjectOverviewFunction = () => this.selectProject(location)
          }

          this.infoWindow.setContent(`<div style="padding: 0 15px">
          <h4 style="margin:10px 0 0">${location.name}</h4>
          <div>
            ${location.description}
            <div class="cwp-project-card__info-item cwp-project-card__info-item--align" style="padding-top: 6px">
              <img
                src="${process.env.VUE_APP_ASSET_PATH}images/icons/location.svg"
                alt="Location marker"
                class="cwp-project-card__icon"
              />
              ${location.location}
            </div>
            <div class="cwp-project-card__info-item cwp-project-card__info-item--align">
              <img
                src="${process.env.VUE_APP_ASSET_PATH}images/icons/building.svg"
                alt="project marker"
                class="cwp-project-card__icon"
              />
              ${location.type}
            </div>
          </div>
          <div class="cwp-project-card__button-container-mobile" style="display:block">
            <a
            style="${window && window.viewProjectOverviewFunction ? '' : 'display: none'}"
              class="button secondary"
              onclick="viewProjectOverviewFunction()"
            >
              Overview
            </a>
            <a
              style="${location?.websiteUrl ? '' : 'display: none'}"
              class="button secondary"
              href="${location?.websiteUrl}"
              target="_blank"
            >
              Visit website
            </a>
          </div>
        </div>`)

          this.infoWindow.open({
            anchor: marker,
            map: this.map,
          })
        })
      }

      this.markers.push(marker)
    },
  },
}
</script>

<style lang="scss">
.cwp-map {
  height: 400px;
  position: relative;

  &__frame {
    height: 100%;
  }
}
</style>
