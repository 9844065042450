<template>
  <section
    class="cwp-contact-and-display-suite-details"
    :class="{
      'cwp-contact-and-display-suite-details--side-by-side': sideBySide,
    }"
  >
    <div class="grid-container padding-y cwp-contact-and-display-suite-details__container">
      <div class="cwp-contact-and-display-suite-details__section">
        <component
          :is="container.headingStyle"
          v-if="container.contactDetailItems"
          class="cwp-contact-and-display-suite-details__contact-heading"
          >{{ container.contactHeading }}</component
        >
        <div v-if="container.contactDetailItems" class="cwp-contact-and-display-suite-details__contact-grid">
          <template v-for="(contact, i) in container.contactDetailItems">
            <div :key="`${i}-1`" class="cwp-contact-and-display-suite-details__contact">
              <h5 class="cwp-contact-and-display-suite-details__full-name">{{ contact.fullName }}</h5>
              <div class="cwp-contact-and-display-suite-details__position">
                {{ contact.position }}
              </div>
            </div>
            <figure :key="`${i}-2`" class="cwp-contact-and-display-suite-details__image-container">
              <img :src="contact.image" :alt="contact.fullName" class="cwp-contact-and-display-suite-details__image" />
            </figure>
            <div :key="`${i}-3`" class="cwp-contact-and-display-suite-details__links">
              <cwp-link class="cwp-contact-and-display-suite-details__link" :href="`tel:${contact.mobileNumber}`">{{
                contact.mobileNumber
              }}</cwp-link>
              <cwp-link
                v-if="contact.phoneNumber"
                :href="`tel:${contact.phoneNumber}`"
                class="cwp-contact-and-display-suite-details__link"
                @click="$fbq.track('Contact')"
                >{{ contact.phoneNumber }}</cwp-link
              >
              <cwp-link
                v-if="contact.emailAddress"
                :href="`mailto:${contact.emailAddress}`"
                class="cwp-contact-and-display-suite-details__link cwp-contact-and-display-suite-details__link--email"
                @click="$fbq.track('Contact')"
                >{{ contact.emailTextLink || contact.emailAddress }}</cwp-link
              >
              <cwp-social-links :key="`${i}-7`" :contacts="contact" />
            </div>
          </template>
        </div>
      </div>
      <div
        v-if="container.displaySuiteDetailItems"
        class="cwp-contact-and-display-suite-details__display-suite-grid"
        :class="{
          'cwp-contact-and-display-suite-details--full-width': fullWidth,
        }"
      >
        <template v-for="(displaySuite, i) in container.displaySuiteDetailItems">
          <component
            :is="container.headingStyle"
            :key="`${i}-1`"
            class="cwp-contact-and-display-suite-details__display-suite-heading"
            >{{ displaySuite.heading }}</component
          >
          <div
            v-if="displaySuite.description || !fullWidth"
            :key="`${i}-2`"
            class="cwp-contact-and-display-suite-details__description"
          >
            <div v-if="displaySuite.description" v-html="displaySuite.description" />
          </div>
          <div :key="`${i}-3`" class="cwp-contact-and-display-suite-details__address">
            <h5 class="cwp-contact-and-display-suite-details__header">Address</h5>
            <div v-html="displaySuite.address" />
          </div>
          <div :key="`${i}-4`" class="cwp-contact-and-display-suite-details__opening-hours">
            <h5 class="cwp-contact-and-display-suite-details__header">Opening hours</h5>
            <div v-html="displaySuite.openingHours" />
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpSocialLinks from '@/components/CwpSocialLinks/CwpSocialLinks.vue'
export default {
  name: 'CwpContactAndDisplaySuiteDetails',
  components: {
    CwpLink,
    CwpSocialLinks,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sideBySide() {
      return this.container.displaySuiteDetailItems?.length === 1 && this.container.contactDetailItems?.length === 1
    },
    fullWidth() {
      return this.container.displaySuiteDetailItems?.length === 1
    },
  },
}
</script>

<style lang="scss">
.cwp-contact-and-display-suite-details {
  background-color: var(--background-color);

  &__contact-heading {
    margin-bottom: 0;

    @include media('>=medium') {
      margin-bottom: 16px;
    }
  }

  &__contact-grid {
    @include grid(12, 0 8px, 0 24px);

    @include media('>=medium') {
      grid-auto-flow: column;
      grid-template-rows: max-content auto;
    }
  }

  &__contact {
    margin: 16px 0;
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: span 6;
    }

    @include media('>=large') {
      grid-column: span 3;
      margin: 0;
    }
  }

  &__full-name {
    margin: 0 0 8px;
  }

  &__image-container {
    margin: 0;
    position: relative;
    grid-column: 1 / span 6;
    padding-top: 77%;

    @include media('>=medium') {
      grid-column: span 3;
    }

    @include media('>=large') {
      grid-row: span 2;
      grid-column: 1 / span 3;

      &:nth-of-type(2) {
        grid-column: 7 / span 3;
      }
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
  }

  &__links {
    grid-column: span 6;
    margin: -8px 0;

    @include media('>=medium') {
      grid-column: span 3;
    }

    @include media('>=large') {
      margin: 0;
    }
  }

  &__link {
    display: block;
    margin: 8px 0;
    text-decoration: none;

    &--email {
      color: var(--body-text-hyperlink-color);
    }
  }

  &__display-suite-grid {
    @include grid(12, 0 8px, 0 24px);

    @include media('>=large') {
      grid-auto-flow: column;
      grid-template-rows: auto auto auto;
    }
  }

  &__display-suite-heading {
    grid-column: span 12;
    margin-bottom: 0;

    @include media('>=large') {
      grid-column: span 6;
    }
  }

  &__description {
    grid-column: span 12;

    @include media('>=large') {
      grid-column: span 6;
    }
  }

  &__address,
  &__opening-hours {
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: span 6;
    }

    @include media('>=large') {
      grid-column: span 3;
    }
  }

  &--full-width {
    grid-auto-flow: row;

    .cwp-contact-and-display-suite-details {
      &__display-suite-heading {
        grid-column: span 12;
      }

      @include media('>=medium') {
        &__description {
          grid-column: span 12;
        }
      }

      @include media('>=large') {
        &__description {
          grid-column: span 6;
        }
      }
    }
  }

  &--side-by-side {
    .cwp-contact-and-display-suite-details {
      @include media('>=large') {
        &__section {
          width: 50%;
        }

        &__container {
          display: flex;
        }

        &__display-suite-heading {
          grid-column: span 6;
        }

        &__contact-grid {
          @include grid(6, 0 8px, 0 24px);
        }

        &__display-suite-grid {
          @include grid(6, 0 8px, 0 24px);

          width: 50%;
        }
      }
    }
  }

  &__header {
    margin: 16px 0;
    line-height: 1.25;
  }

  .cwp-social-links {
    margin: 0 -8px;
    color: var(--body-text-hyperlink-color);
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    &__item {
      padding: 0 8px;
    }
  }
}
</style>
