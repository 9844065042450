<template>
  <section class="cwp-project-finder">
    <cwp-project-map
      v-if="!container.hideMap"
      :locations="filteredProjects"
      :state="container.state"
      :select-project="selectProject"
    />
    <div class="grid-container">
      <div
        v-if="!container.hideMap"
        class="cwp-project-finder__status-marker-container"
        :class="container.headerText ? '' : 'cwp-project-finder__status-marker-container--add-margin'"
      >
        <CwpStatusMarker status="complete" />
        <CwpStatusMarker status="in progress" />
        <CwpStatusMarker status="coming soon" />
      </div>
      <h1 v-if="container.headerText">{{ container.headerText }}</h1>
      <div v-if="showFilters" class="cwp-project-finder__filters-container">
        <div
          v-for="(filter, filterId) in filterSelects"
          v-show="filter.show"
          :key="filterId"
          class="cwp-project-finder__filter-input-container"
        >
          <label :for="filterId">
            <cwp-icon :name="`${filter.icon}.svg`" alt="Location marker" class="filter-icon" />
            {{ filter.label }}
          </label>
          <rv-select
            :ref="filterId"
            class="filter-input"
            :field="{
              id: filterId,
              name: filterId,
              value: filters[filterId],
              options: filter.options,
            }"
            @input="
              (formName, fieldName, value) => {
                filters[filterId] = value
              }
            "
          />
        </div>

        <div class="cwp-project-finder__filter-input-container">
          <label for="Search">
            <cwp-icon name="search.svg" alt="Location marker" class="filter-icon" />
            Search
          </label>
          <rv-input
            ref="searchTerm"
            class="filter-input"
            :field="{
              id: 'searchTerm',
              name: 'searchTerm',
              placeholder: '',
              type: 'text',
              value: filters.searchTerm,
            }"
            @input="
              (formName, fieldName, value) => {
                filters.searchTerm = value
              }
            "
          />
        </div>
      </div>
      <cwp-link v-if="showFilters" button class="button secondary clear-filters" tag="button" @click="clearFilters">
        Clear Filters
      </cwp-link>

      <div v-if="filteredProjects.length" class="cwp-project-finder__projects-container">
        <CwpProjectCard
          v-for="project in filteredProjects"
          :key="project.name"
          :project-data="project"
          :select-project="selectProject"
          :base-url="container.baseUrl"
        />
      </div>
      <div v-else class="cwp-project-finder__no-projects">
        <p><strong>No projects found</strong></p>
      </div>
    </div>

    <cwp-modal
      :value="!!selectedProject"
      prevent-scroll-top
      size="large"
      remove-padding
      @input="() => selectProject(null)"
    >
      <cwp-project-detail
        v-if="!!selectedProject"
        :project-data="selectedProject"
        :base-url="container.baseUrl"
      ></cwp-project-detail>
    </cwp-modal>
  </section>
</template>
<script>
import axios from 'axios'

import { RvSelect, RvInput } from '@revium/components'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpModal from '@/components/CwpModal/CwpModal.vue'

import CwpProjectMap from './CwpProjectMap.vue'
import CwpProjectCard from './CwpProjectCard.vue'
import CwpProjectDetail from './CwpProjectDetail.vue'
import CwpStatusMarker from './CwpStatusMarker.vue'

export default {
  name: 'CwpProjectListing',
  components: {
    CwpStatusMarker,
    RvSelect,
    CwpIcon,
    RvInput,
    CwpLink,
    CwpProjectMap,
    CwpProjectCard,
    CwpModal,
    CwpProjectDetail,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      projects: [],
      selectedProject: null,
      filters: {
        projectLocation: '',
        projectType: '',
        projectStatus: '',
        searchTerm: '',
      },
      filterSelects: {
        projectLocation: {
          label: 'Project Location',
          icon: 'location',
          options: [{ value: '', text: 'All' }],
          show: true,
        },
        projectType: {
          label: 'Project Type',
          options: [{ value: '', text: 'All' }],
          icon: 'building',
          show: true,
        },
        projectStatus: {
          label: 'Project Status',
          options: [{ value: '', text: 'All' }],
          icon: 'circle',
          show: true,
        },
      },
      showFilters: true,
      projectLocationFilter: 'location',
    }
  },
  computed: {
    filteredProjects() {
      let filteredProjects = this.projects
      for (const filterKey in this.filters) {
        filteredProjects = filteredProjects.filter(project => {
          if (!this.filters[filterKey]) {
            return true
          }

          if (filterKey === 'projectLocation') {
            return project[this.projectLocationFilter] === this.filters.projectLocation
          }

          if (filterKey === 'projectType') {
            return project.subCategories.includes(this.filters.projectType)
          }

          if (filterKey === 'projectStatus') {
            return project.status === this.filters.projectStatus
          }

          if (filterKey === 'searchTerm') {
            const lowerCaseSearchTerm = this.filters.searchTerm.toLowerCase()
            return [
              project.name.toLowerCase().includes(lowerCaseSearchTerm),
              project.description.toLowerCase().includes(lowerCaseSearchTerm),
            ].some(value => value)
          }
        })
      }

      return filteredProjects
    },
  },
  mounted() {
    axios({
      method: 'get',
      url: this.container.endpoint,
    })
      .then(response => {
        const _projects = response?.data?.results

        if (_projects?.length) {
          // Set projects

          const typeMapping = {
            res: 'residential',
            com: 'commercial',
            mu: 'mixed-use',
          }

          const preSetFilters = {
            ...(this.container.subTypes?.length && { subCategories: this.container.subTypes }),
            ...(this.container.state && { state: this.container.state }),
            ...(this.container.landCorridor && { landCorridor: this.container.landCorridor }),
            ...(this.container.statuses?.length && { status: this.container.statuses }),
            ...(this.container.type && {
              type: typeMapping[this.container.type.toLowerCase()],
            }),
          }

          function filterProject(project) {
            return Object.keys(preSetFilters).every(key => {
              const filterValue = preSetFilters[key]
              const projectValue = project[key]

              if (!projectValue) return false

              if (Array.isArray(filterValue)) {
                if (Array.isArray(projectValue)) {
                  return filterValue.some(value => projectValue.includes(value))
                }

                return filterValue.includes(projectValue)
              }

              return projectValue === filterValue
            })
          }

          const filteredProjects = _projects.filter(project => filterProject(project))

          this.projects = filteredProjects.sort((a, b) => {
            if (
              (a.status === 'coming soon' || a.status === 'in progress') &&
              b.status !== 'coming soon' &&
              b.status !== 'in progress'
            ) {
              return -1
            }

            if (
              (b.status === 'coming soon' || b.status === 'in progress') &&
              a.status !== 'coming soon' &&
              a.status !== 'in progress'
            ) {
              return 1
            }

            if (
              (a.status === 'coming soon' || a.status === 'in progress') &&
              (b.status === 'coming soon' || b.status === 'in progress')
            ) {
              return a.name.localeCompare(b.name)
            }

            if (a.status === 'complete' && b.status === 'complete') {
              return a.name.localeCompare(b.name)
            }

            return 0
          })

          if (this.projects.length <= 1 || this.container.hideFilters) {
            this.showFilters = false
          }

          this.projectLocationFilter = this.container.landCorridor ? 'location' : 'landCorridor'

          // Set filter options
          const projectLocations = [
            ...new Set(this.projects.map(project => project[this.projectLocationFilter])),
          ].filter(location => location)

          // Add optGrouping (labelling the state the landCorridors are from) only when landCorridor filtering is null
          if (preSetFilters.landCorridor) {
            this.setFilterSelect(projectLocations, this.filterSelects.projectLocation)
          } else {
            this.setFilterSelect(
              projectLocations,
              this.filterSelects.projectLocation,
              `${preSetFilters.state} Projects`
            )
          }

          const projectTypes = this.container.typeResidential
            ? []
            : [...new Set(this.projects.reduce((value, project) => [...value, ...project.subCategories], []))].filter(
                type => type
              )
          this.setFilterSelect(projectTypes, this.filterSelects.projectType)

          const projectStatus = [...new Set(this.projects.map(project => project.status))].filter(status => status)
          this.setFilterSelect(projectStatus, this.filterSelects.projectStatus)
        } else {
        }
      })
      .catch(() => {
        this.showFilters = false
        this.projects = []
      })
  },
  methods: {
    setFilterSelect(values, filterSelect, optGroupLabel) {
      if (values.length < 1) {
        filterSelect.show = false
        return
      }

      const options = values.map(value => ({ value: value, text: this.capitalizeWords(value) }))

      options.unshift({ value: '', text: 'All' })

      if (optGroupLabel) {
        filterSelect.options = [{ isOptGroup: true, label: optGroupLabel, options }]
      } else {
        filterSelect.options = options
      }
    },
    clearFilters() {
      Object.keys(this.filterSelects).forEach(filter => {
        this.filters[filter] = ''
        if (this.$refs[filter]) this.$refs[filter][0].fieldValue = ''
      })
    },
    selectProject(projectData) {
      this.selectedProject = projectData
    },
    capitalizeWords(str) {
      return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
  },
}
</script>

<style lang="scss">
.cwp-project-finder {
  &__status-marker-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    &--add-margin {
      margin: 32px 0;
    }

    > div:not(:last-child) {
      margin-right: 20px;
    }

    @media (max-width: 363px) {
      display: none;
    }
  }

  &__filters-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;

    @include media('>=medium') {
      flex-direction: row;
      justify-content: start;
    }

    @include media('>=large') {
      flex-wrap: nowrap;
    }

    label {
      white-space: nowrap;
    }
  }

  &__filter-input-container {
    margin-bottom: 10px;

    @include media('>=medium') {
      width: calc(50% - 10px);
    }

    &:nth-child(odd) {
      @include media('>=medium') {
        margin-right: 20px;
      }
    }

    @include media('>=large') {
      width: calc(25% - 10px);
      margin-right: 20px;
      margin-bottom: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    label {
      display: flex;
      font-weight: var(--strong-text-font-weight);
      padding-bottom: 8px;
      align-items: center;
    }

    .filter-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    .filter-input {
      width: 100%;
    }
  }

  &__projects-container {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    margin: 32px 0;

    @include media('>=medium') {
      grid-template-columns: 1fr 1fr;
    }

    @include media('>=large') {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__no-projects {
    padding: 35px 0;
  }

  .clear-filters {
    width: 100%;
    margin-top: 12px;

    @include media('>=medium') {
      width: auto;
      margin-top: 0;
    }
  }
}
</style>
