<template>
  <section class="cwp-document-container">
    <div class="grid-container padding-y">
      <div class="cwp-document-container__grid">
        <div v-if="container.showFilters" class="cwp-document-container__filters">
          <rv-expansion-panel v-model="expanded" class="cwp-document-container__accordion">
            <template #toggle="{ toggle }">
              <div class="cwp-document-container__toggle" @click="toggle" @keyup.enter="toggle">
                <h4 class="rv-expansion-panel__label">Filter document list</h4>
                <span class="rv-expansion-panel__chevron" />
              </div>
            </template>

            <template #default>
              <h4 class="cwp-document-container__filter-header">Filter document list</h4>

              <ValidationObserver v-slot="{ handleSubmit, invalid }">
                <form class="rv-form" @submit.prevent="handleSubmit(applyFilters)">
                  <template v-if="container.showStageLotFilter">
                    <rv-radio
                      field-name="radios"
                      class="cwp-document-container__filter"
                      :field="fields.radios"
                      value="stage"
                      @input="
                        (formName, fieldName, value) => {
                          developmentType = value
                        }
                      "
                    />
                    <rv-select
                      v-show="developmentType === 'stage'"
                      key="stage"
                      ref="stage"
                      field-name="stage"
                      class="cwp-document-container__filter"
                      :field="fields.stage"
                      @input="
                        (formName, fieldName, value) => {
                          filters.stage = value
                        }
                      "
                    />
                    <rv-input
                      v-show="developmentType === 'lot'"
                      key="lotNumbers"
                      ref="lotNumbers"
                      field-name="lotNumbers"
                      class="cwp-document-container__filter"
                      :field="fields.lotNumbers"
                      @input="
                        (formName, fieldName, value) => {
                          filters.lotNumbers = value
                        }
                      "
                    >
                      <template #messages="{ errors }">
                        <cwp-messages :field="fields.lotNumbers" :errors="errors" />
                      </template>
                    </rv-input>
                  </template>

                  <rv-select
                    ref="category"
                    field-name="category"
                    class="cwp-document-container__filter"
                    :field="fields.category"
                    @input="
                      (formName, fieldName, value) => {
                        filters.category = value
                      }
                    "
                  />

                  <rv-input
                    ref="keyword"
                    field-name="keyword"
                    class="cwp-document-container__filter"
                    :field="fields.keyword"
                    @input="
                      (formName, fieldName, value) => {
                        filters.keyword = value
                      }
                    "
                  />

                  <rv-checkbox
                    ref="exactKeywordMatch"
                    field-name="exactKeywordMatch"
                    :field="fields.exactKeywordMatch"
                    :value="false"
                    @input="
                      (formName, fieldName, value) => {
                        filters.exactKeywordMatch = value
                      }
                    "
                  />

                  <div class="cwp-document-container__buttons">
                    <cwp-link
                      :loading="loading"
                      :disabled="invalid"
                      button
                      class="button primary"
                      tag="button"
                      @click="handleSubmit(applyFilters)"
                    >
                      Apply filters
                    </cwp-link>
                    <cwp-link :disabled="loading" button class="button secondary" tag="button" @click="resetFilters">
                      Reset filters
                    </cwp-link>
                  </div>
                </form>
              </ValidationObserver>
            </template>
          </rv-expansion-panel>
        </div>

        <div
          class="cwp-document-container__category-items"
          :class="{ 'cwp-document-container__category-items--centered': !container.showFilters }"
        >
          <div
            v-for="(categoryItem, i) in categoryItems"
            :key="i"
            :class="utilityClassGenerator('cwp-document-container', categoryItem)"
            class="cwp-document-container__category"
          >
            <component
              :is="container.categoryHeadingStyle"
              v-if="categoryItem.name"
              class="cwp-document-container__heading"
            >
              {{ categoryItem.name }}
            </component>
            <p
              v-if="categoryItem.description"
              class="cwp-document-container__description"
              v-html="categoryItem.description"
            />
            <div class="cwp-document-container__document-grid">
              <div v-for="(document, i2) in categoryItem.documentItems" :key="i2" class="cell">
                <cwp-link :href="document.url" class="cwp-document-container__document" target="_blank">
                  <cwp-icon class="cwp-document-container__icon" name="file-download.svg" />
                  <div class="cwp-document-container__document-name">{{ document.name }}</div>
                </cwp-link>
              </div>
            </div>
          </div>
          <cwp-loader :loading="loading" class="cwp-loader--absolute" />
          <h4 v-if="!loading && categoryItems.length === 0" class="cwp-document-container__no-results">
            No documents available
          </h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { RvCheckbox, RvInput, RvExpansionPanel, RvRadio, RvSelect } from '@revium/components'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpLoader from '@/components/CwpLoader/CwpLoader.vue'
import CwpMessages from '@/components/CwpMessages/CwpMessages.vue'
import Kentico from '@/api/kentico'
import { mapState, mapGetters } from 'vuex'
import { utilityClassGenerator, scrollTo } from '@/functions/utils.js'

export default {
  name: 'CwpDocumentContainer',
  components: {
    CwpLink,
    CwpIcon,
    CwpLoader,
    CwpMessages,
    RvCheckbox,
    RvInput,
    RvExpansionPanel,
    RvRadio,
    RvSelect,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    const defaultFilters = {
      stage: '',
      lotNumbers: '',
      category: '',
      keyword: '',
      exactKeywordMatch: false,
    }
    return {
      defaultFilters,
      filters: { ...defaultFilters },
      developmentType: '',
      categoryItems: [],
      loading: true,
      expanded: true,
      initial: true,
      fields: {
        radios: {
          id: `radios-${this.container.id}`,
          name: `radios-${this.container.id}`,
          type: 'radio',
          options: [
            { text: 'Stage', value: 'stage' },
            { text: 'Lot', value: 'lot' },
          ],
        },
        stage: {
          id: `stage-${this.container.id}`,
          name: `stage-${this.container.id}`,
          label: 'Stage',
          placeholder: 'Select stage',
          type: 'select',
          options: this.container.filter.stages.map(({ name, value }) => ({
            text: name,
            value,
          })),
        },
        lotNumbers: {
          id: `lot-${this.container.id}`,
          name: `lot-${this.container.id}`,
          label: 'Lot numbers',
          placeholder: 'Comma-separated',
          type: 'text',
        },
        category: {
          id: `category-${this.container.id}`,
          name: `category-${this.container.id}`,
          label: 'Document category',
          placeholder: 'Show all categories',
          type: 'select',
          options: [],
        },
        keyword: {
          id: `keyword-${this.container.id}`,
          name: `keyword-${this.container.id}`,
          label: 'Keywords',
          placeholder: 'Enter keywords',
          type: 'text',
        },
        exactKeywordMatch: {
          id: `exactKeywordMatch-${this.container.id}`,
          name: `exactKeywordMatch-${this.container.id}`,
          label: 'Exact keyword match',
          type: 'checkbox',
        },
      },
    }
  },
  computed: {
    ...mapGetters('site', ['componentIndex']),
    ...mapState({
      breakpoints: state => state.settings.breakpoints,
    }),
    index() {
      return this.componentIndex({ className: 'Revium_DocumentContainer', id: this.container.id })
    },
  },
  watch: {
    // If screen size goes to desktop, open accordion, else close
    'breakpoints.large'(newVal) {
      this.expanded = newVal
    },
  },
  mounted() {
    this.expanded = this.breakpoints.large
    this.applyFilters().then(categoryItems => {
      if (categoryItems) {
        this.fields.category.options = categoryItems.map(category => ({
          text: category.name,
          value: category.name,
        }))
      }
    })
  },
  methods: {
    applyFilters() {
      this.loading = true
      this.categoryItems = []
      return Kentico.getDocuments(this.$route.path, {
        ...this.filters,
        stage: this.developmentType === 'stage' ? this.filters.stage : '',
        lotNumbers:
          this.developmentType === 'lot'
            ? this.filters.lotNumbers
                .split(',')
                .map(str => str.trim())
                .join(',')
            : '',
      })
        .then(response => {
          if (response?.data[this.index]) {
            this.categoryItems = response.data[this.index].categoryItems || []
            return this.categoryItems
          }
        })
        .finally(() => {
          this.loading = false
          if (this.initial) {
            this.initial = false
          } else if (!this.breakpoints.large) {
            scrollTo(this.$el.querySelector('.cwp-document-container__category-items'), -16)
          }
        })
    },
    resetFilters() {
      this.filters = { ...this.defaultFilters }
      Object.keys(this.filters).forEach(filter => {
        if (this.$refs[filter]) this.$refs[filter].fieldValue = ''
      })
      this.applyFilters()
    },
    utilityClassGenerator,
  },
}
</script>

<style lang="scss">
.cwp-document-container {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__filters {
    grid-column: span 12;

    @include media('>=large') {
      grid-column: span 3;
    }
  }

  &__category-items {
    position: relative;
    grid-column: span 12;

    @include media('>=large') {
      grid-column: 5 / span 8;

      &--centered {
        grid-column: 3 / span 8;
      }
    }
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;

    @include media('>=large') {
      display: none;
    }
  }

  &__filter {
    margin: 16px 0;
    display: block;
  }

  &__filter-header {
    margin: 0 0 8px;
    display: none;

    @include media('>=large') {
      display: block;
    }
  }

  &__buttons {
    margin: 16px -8px;
    display: flex;
    flex-wrap: wrap;

    @include media('>=large') {
      justify-content: space-between;
    }
    .cwp-link {
      margin: 8px;
    }
  }

  &__category + &__category {
    margin-top: 24px;
  }

  &__heading {
    margin: 0;
    line-height: 1;
  }

  &__document-grid {
    @include grid(20, 8px 8px, 12px 24px);

    margin: 24px 0;
  }

  &__document {
    display: inline-flex;
    color: var(--body-text-hyperlink-color);
    text-decoration: none;
  }

  &__icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }

  &__document-name {
    padding: 4px 0 0 8px;
  }

  &__no-results {
    margin: 0;
  }

  .cell {
    grid-column: span 20;

    @include media('>=medium') {
      grid-column: span 10;
    }
  }

  &__layout--four-columns .cell {
    grid-column: span 10;

    @include media('>=medium') {
      grid-column: span 5;
    }
  }

  &__layout--five-columns .cell {
    grid-column: span 10;

    @include media('>=medium') {
      grid-column: span 4;
    }
  }

  .rv-expansion-panel__chevron {
    margin-right: 0;
  }

  .rv-radio .rv-form__label:last-of-type {
    margin-bottom: 0;
  }
}
</style>
