<template>
  <div class="cwp-newsletter">
    <cwp-generic-form :container="container" submit-text="Join the newsletter" persist-heading />
  </div>
</template>

<script>
import CwpGenericForm from '@/components/CwpGenericForm/CwpGenericForm.vue'
export default {
  name: 'CwpNewsletter',
  components: {
    CwpGenericForm,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.cwp-newsletter {
  background-color: var(--background-color);

  .cwp-generic-form {
    &__wrapper {
      @include media('>=medium') {
        grid-column: span 6;
      }
    }

    &__header {
      text-align: left;
      margin: 0;
      grid-column: span 12;

      @include media('>=medium') {
        grid-column: span 6;
      }
    }

    &__heading {
      margin: 0 0 8px 0;
    }

    &__description {
      margin: 8px 0;
    }
  }

  .rv-form__buttons {
    text-align: left;
  }
}
</style>
