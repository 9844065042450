var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cwp-carousel-banner-container cwp-carousel-container"},[(_vm.slides.length)?_c('rv-carousel',{staticClass:"cwp-carousel-container__carousel",class:{
      'rv-carousel--hide-drag': _vm.slides.length === 1,
      'rv-carousel--overlaid': _vm.container.textLayout === 'overlaid',
      'rv-carousel--below': _vm.container.textLayout !== 'overlaid',
    },attrs:{"name":`carousel-${_vm.container.id}`,"navigation":_vm.slides.length > 1,"pagination":true,"scrollbar":false,"options":{
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      observer: true,
      observeParents: true,
      slidesPerView: '1',
      centeredSlides: true,
      slidesPerColumnFill: 'row',
    }},scopedSlots:_vm._u([{key:"prev",fn:function(){return [_c('cwp-icon',{attrs:{"name":"angle-left.svg"}})]},proxy:true},{key:"next",fn:function(){return [_c('cwp-icon',{attrs:{"name":"angle-right.svg"}})]},proxy:true}],null,false,3026583172)},_vm._l((_vm.slides),function(slide){return _c('div',{key:slide.index,class:{
        'rv-carousel__slide': true,
      }},[_c('rv-banner',{class:`guid-${slide.id}`,attrs:{"picture":_vm.getPicture(slide),"content-position":slide.textAlignment},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"rv-banner__content"},[_c('div',{staticClass:"rv-banner__content-box",class:{ 'rv-banner--overlay': slide.displayOverlay }},[(slide.heading)?[_c('div',{staticClass:"rv-banner__heading"},[(slide.h1Heading)?_c('h1',[_vm._v(_vm._s(slide.heading))]):_c('h2',[_vm._v(_vm._s(slide.heading))])])]:_vm._e(),(slide.description)?[_c('div',{staticClass:"rv-banner__sub-heading"},[_c('div',{domProps:{"innerHTML":_vm._s(slide.description)}})])]:_vm._e(),(slide.buttonUrl1 || slide.buttonUrl2)?[_c('div',{staticClass:"rv-banner__blurb"},[(slide.buttonUrl1)?_c('cwp-link',{staticClass:"rv-banner__link--1",attrs:{"to":slide.buttonUrl1,"target":slide.buttonOpenNewTab1 ? '_blank' : '',"button":""}},[_vm._v(" "+_vm._s(slide.buttonText1)+" ")]):_vm._e(),(slide.buttonUrl2)?_c('cwp-link',{staticClass:"rv-banner__link--2",attrs:{"to":slide.buttonUrl2,"target":slide.buttonOpenNewTab2 ? '_blank' : '',"button":""}},[_vm._v(" "+_vm._s(slide.buttonText2)+" ")]):_vm._e()],1)]:_vm._e()],2)])]},proxy:true}],null,true)})],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }