<template>
  <section :class="isFloating ? '' : 'grid-container padding-y'">
    <component
      :is="isFloating ? 'cwp-modal' : 'div'"
      v-model="modal"
      :aria-labelledby="`form-label-${container.id}`"
      :aria-describedby="`form-description-${container.id}`"
    >
      <div ref="cwpGenericForm" class="cwp-generic-form" :style="{ minHeight }">
        <div
          v-if="(container.heading || container.description) && (!showThankYouState || persistHeading)"
          class="cwp-generic-form__header"
        >
          <h3 v-if="container.heading" :id="`form-label-${container.id}`" class="cwp-generic-form__heading">
            {{ container.heading }}
          </h3>
          <p
            v-if="container.description"
            :id="`form-description-${container.id}`"
            class="cwp-generic-form__description"
            v-html="container.description"
          />
        </div>
        <div class="cwp-generic-form__wrapper hubspot-form-container">
          <div :id="`hubspot-form-${container.id}`"></div>

          <p v-if="hubSpotDependencyFailure" class="text-center">
            We're sorry, our form provider failed to respond. Would you like to
            <a href="#" @click="retry" @keyup.enter="retry">try again</a>?
          </p>
        </div>
      </div>
    </component>
    <div
      v-if="isFloating && formIcon"
      class="cwp-generic-form__floating-button cwp-generic-form__floating-button--hubspot"
    >
      <cwp-icon :path="formIcon" interactive tabindex="0" alt="Open dialog" @click="modal = true" />
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)

import CwpIcon from '@/components/CwpIcon/CwpIcon.vue'
import CwpModal from '@/components/CwpModal/CwpModal.vue'

export default {
  name: 'CwpHubSpotForm',
  components: {
    CwpModal,
    CwpIcon,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    persistHeading: {
      type: Boolean,
      default: false,
    },
  },
  metaInfo() {
    return {
      // add rea convestion script if id is in the response
      script: [
        this.container?.reaTrackingID
          ? {
              id: 'rea',
              src: 'https://leads.media-tools.realestate.com.au/conversions.js',
              pbody: true,
            }
          : {},
      ],
    }
  },
  data() {
    return {
      modal: false,
      showThankYouState: false,
      hubSpotDependencyFailure: false,
      minHeight: '0',
    }
  },
  computed: {
    isFloating() {
      return this.container?.displayType === 'floating'
    },
    formIcon() {
      return this.container?.formIcon
    },
    formID() {
      return this.container?.formID
    },
    portalID() {
      return this.container?.portalID
    },
    ieVersion() {
      let undef,
        v = 3,
        div = document.createElement('div'),
        all = div.getElementsByTagName('i')

      while (((div.innerHTML = '<!--[if gt IE ' + ++v + ']><i></i><![endif]-->'), all[0]));

      return v > 4 ? v : undef
    },
  },
  mounted() {
    this.mockJquery()
    this.initHubSpot()
  },
  methods: {
    retry(e) {
      e.preventDefault()
      this.initHubSpot()
    },
    async initHubSpot() {
      if (!window.hbspt) {
        const hubspotScript = this.ieVersion < 9 ? 'v2-legacy.js' : 'v2.js'
        try {
          await Vue.loadScript(`https://js.hsforms.net/forms/${hubspotScript}`)
        } catch {
          this.hubSpotDependencyFailure = true
          return
        }
        if (!window.hbspt?.forms) {
          this.hubSpotDependencyFailure = true
          return
        }
      }

      this.setupHubSpotForm()
    },
    setupHubSpotForm() {
      this.hubSpotDependencyFailure = false

      let formValues
      let submitted = false
      window.hbspt.forms.create({
        target: `#hubspot-form-${this.container.id}`,
        portalId: this.portalID,
        formId: this.formID,
        region: 'na1', // #68419 - Intentionally hard-coded
        cssClass: 'hubspot-form',
        onFormSubmit: form => {
          const formData = new FormData(form)
          formValues = this.serialize(formData)
          this.minHeight = this.$refs.cwpGenericForm.offsetHeight + 'px'
        },
        onFormSubmitted: () => {
          // Prevent mutiple Leads tracked due to multiple triggers of onFormSubmitted
          // onFormSubmitted can be fired more than once, on a single submit, within the same stack
          //  if multiple Hubspot forms exist on the same page
          if (formValues && !submitted) {
            this.$fbq.track('Lead')
            this.$gtmTrack.formSuccess(this.container.formName, formValues)
            if (this.container.reaTrackingID && window.REA) {
              REA.track_conversion(`${this.container.reaTrackingID}`)
            }
            this.showThankYouState = true
            submitted = true
          }
        },
      })
    },
    mockJquery() {
      window.jQuery =
        window.jQuery ||
        function (nodeOrSelector) {
          if (typeof nodeOrSelector == 'string') {
            return document.querySelector(s)
          }
          return nodeOrSelector
        }
    },
    serialize(data) {
      let obj = {}
      for (let [key, value] of data) {
        if (obj[key] !== undefined) {
          if (!Array.isArray(obj[key])) {
            obj[key] = [obj[key]]
          }
          obj[key].push(value)
        } else {
          obj[key] = value
        }
      }
      return obj
    },
  },
}
</script>

<style lang="scss" scoped>
.hubspot-form-container {
  .text-center {
    text-align: center;
  }
}
.cwp-generic-form__floating-button--hubspot {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
