<template>
  <div :id="field.id + '-messages'" class="rv-form__messages">
    <span v-if="field.hint" :id="field.id + '-hint'" class="rv-form__hint">{{ field.hint }}</span>
    <ul v-if="errors.length > 0" :id="field.id + '-errors'" :data-test="field.id + '-errors'" class="rv-form__errors">
      <li v-for="(error, key) in errors" :key="key" class="rv-form__error-list">
        {{ error }}
      </li>
    </ul>
    <span v-else-if="!field.hint" class="rv-form__required sr-only">
      This field is {{ field.validation && field.validation.required ? 'required' : 'optional' }}.
    </span>
  </div>
</template>

<script>
export default {
  name: 'CwpMessages',
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
