<template>
  <section class="cwp-vertical-card-container">
    <div class="grid-container padding-y">
      <div class="cwp-vertical-card-container__grid">
        <div v-if="container.heading || container.descriptionText" class="cwp-vertical-card-container__description">
          <h3 v-if="container.heading" class="cwp-vertical-card-container__heading">{{ container.heading }}</h3>
          <p v-if="container.descriptionText" class="cwp-vertical-card-container__description-text">
            {{ container.descriptionText }}
          </p>
        </div>
      </div>
      <div class="cwp-vertical-card-container__grid">
        <component
          :is="container.componentType === 'simple' && card.cardUrl ? 'cwp-link' : 'section'"
          v-for="(card, i) in container.cards"
          :key="i"
          :target="container.componentType === 'simple' && card.cardUrl && card.cardButtonOpenNewTab ? '_blank' : ''"
          :to="card.cardUrl"
          class="cell"
          wrapper
        >
          <rv-card :card="card" :image-options="{ width: 570 }">
            <template #content>
              <div v-if="card.title" class="rv-card__title">
                {{ card.title }}
              </div>
              <div v-if="card.content" class="rv-card__content" v-html="card.content" />
              <div class="rv-card__cta">
                <cwp-link
                  v-if="card.buttonText && card.buttonUrl"
                  :to="card.buttonUrl"
                  :target="card.cardButtonOpenNewTab ? '_blank' : ''"
                  button
                  class="rv-card__button"
                >
                  {{ card.buttonText }}
                </cwp-link>
              </div>
            </template>
          </rv-card>
        </component>
      </div>
      <div v-if="container.displayCtaButtonBelowCards" class="cwp-vertical-card-container__cta-button">
        <cwp-link :to="container.buttonUrl" button primary :target="container.buttonOpenNewTab ? '_blank' : ''">
          {{ container.buttonText }}
        </cwp-link>
      </div>
    </div>
  </section>
</template>

<script>
import { RvCard } from '@revium/components'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
export default {
  name: 'CwpVerticalCardContainer',
  components: {
    CwpLink,
    RvCard,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.cwp-vertical-card-container {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__description {
    text-align: var(--description-text-alignment);
    color: var(--text-color);
    grid-column: span 12;
    padding-bottom: 24px;

    @include media('>=medium') {
      grid-column: 3 / span 8;
    }
  }

  &__heading {
    margin: 0;
    color: currentColor;
  }

  &__description-text {
    margin: 0;
  }

  &__cta-button {
    text-align: center;
    margin-top: 16px;

    @include media('>=medium') {
      margin-top: 32px;
    }
  }

  .cell {
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: 4 / span 6;
    }
  }

  @include media('>=medium') {
    &__number-of-cards-per-row--4 .cell {
      grid-column: span 3;
    }

    &__number-of-cards-per-row--3 .cell {
      grid-column: span 4;
    }

    &__number-of-cards-per-row--2 .cell {
      grid-column: span 4;

      &:first-child,
      &:nth-child(2n + 3) {
        grid-column: 3 / span 4;
      }
    }
  }
}
</style>
