<template>
  <section :class="`cwp-related-content__last-row--${lastRow}`" class="cwp-related-content">
    <div class="grid-container padding-y">
      <div class="cwp-related-content__grid">
        <div v-if="container.heading || container.descriptionText" class="cwp-related-content__description">
          <h3 v-if="container.heading" class="cwp-related-content__heading">{{ container.heading }}</h3>
          <p v-if="container.descriptionText" class="cwp-related-content__description-text">
            {{ container.descriptionText }}
          </p>
        </div>
        <div v-for="(card, i) in container.items" :key="i" class="cell">
          <rv-card :card="card" :image-options="{ width: 570 }">
            <template #content>
              <div v-if="card.title" class="rv-card__title">
                {{ card.title }}
              </div>
              <div v-if="card.pageSummary" class="rv-card__content" v-html="card.pageSummary" />
              <div class="rv-card__cta">
                <cwp-link v-if="card.url" :to="card.url" button class="rv-card__button"> Read more </cwp-link>
              </div>
            </template>
          </rv-card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { RvCard } from '@revium/components'
import CwpLink from '@/components/CwpLink/CwpLink.vue'
export default {
  name: 'CwpRelatedContent',
  components: {
    CwpLink,
    RvCard,
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  computed: {
    lastRow() {
      return this.container.items ? this.container.items.length % 3 : 0
    },
  },
}
</script>

<style lang="scss">
.cwp-related-content {
  background-color: var(--background-color);

  &__grid {
    @include grid(12);
  }

  &__description {
    text-align: var(--description-text-alignment);
    color: var(--text-color);
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: 3 / span 8;
    }
  }

  &__heading {
    margin: 0;
    color: currentColor;
  }

  &__description-text {
    margin: 0;
  }

  .cell {
    grid-column: span 12;

    @include media('>=medium') {
      grid-column: span 4;
    }
  }

  @include media('>=medium') {
    &__last-row--1 .cell:last-child {
      grid-column: 5 / span 4;
    }

    &__last-row--2 .cell:nth-last-child(2) {
      grid-column: 3 / span 4;
    }
  }
}
</style>
