var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',{staticClass:"cwp-date",attrs:{"rules":{ ..._vm.field.validation, date: true },"name":_vm.field.name,"custom-messages":{
    ..._vm.field.messages,
    date: 'Must be valid date: DD/MM/YYYY',
  },"vid":_vm.field.name},scopedSlots:_vm._u([{key:"default",fn:function({ errors, ariaInput, validate, classes }){return [_c('div',{class:{ ...classes }},[(_vm.field.tooltip)?_c('div',{staticClass:"cwp-date__tooltip"},[(_vm.field.tooltip.icon)?_c('div',{staticClass:"cwp-date__tooltip-icon"},[_c('span',{class:`${_vm.field.tooltip.icon.name}`})]):_vm._e(),(_vm.field.tooltip.content)?_c('div',{staticClass:"cwp-date__tooltip-content"},[_vm._v(" "+_vm._s(_vm.field.tooltip.content)+" ")]):_vm._e()]):_vm._e(),(_vm.field.label)?_c('label',{staticClass:"rv-form__label",attrs:{"for":_vm.field.id}},[_vm._v(" "+_vm._s(_vm.field.label)+" "),(_vm.field.validation ? _vm.field.validation.required : '')?_c('sup',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('v-date-picker',{attrs:{"input-props":{
        placeholder: _vm.placeholder,
      },"disabled-dates":_vm.disabledDates,"first-day-of-week":1,"masks":{
        L: 'DD/MM/YYYY',
      }},on:{"input":_vm.updateFieldValue},scopedSlots:_vm._u([{key:"default",fn:function({ updateValue, hidePopover }){return [_c('cleave',_vm._b({ref:"cleave",staticClass:"rv-form__field",attrs:{"id":_vm.field.id,"value":_vm.cleaveFieldValue,"raw":false,"options":{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] },"placeholder":_vm.placeholder,"name":_vm.field.name,"maxlength":"10","type":"tel"},nativeOn:{"keyup":function($event){return (ev => _vm.handleUpdate(ev, updateValue, validate, hidePopover)).apply(null, arguments)},"change":function($event){return (ev => _vm.handleUpdate(ev, updateValue, validate, hidePopover, true)).apply(null, arguments)}}},'cleave',ariaInput,false))]}}],null,true),model:{value:(_vm.fieldValue),callback:function ($$v) {_vm.fieldValue=$$v},expression:"fieldValue"}}),_vm._t("messages",null,{"errors":errors})],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }